<template>
  <div :class="classGroup">
    <svg xmlns="http://www.w3.org/2000/svg" :width="widthProp" :height="heightProp" viewBox="0 0 3.762 25.92">
      <path class="a" d="M-1.881,0V-3.654H1.881V0Zm0-6.48V-25.92H1.881V-6.48Z" transform="translate(1.881 25.92)" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      classGroup: "icon",
      heightProp: 16,
      widthProp: 3.763,
    };
  },

  created() {
    if (this.small) {
      this.classGroup = this.classGroup + " icon-small";
      this.heightProp = 16;
      this.widthProp = 3.763;
    }
    if (this.large) {
      this.classGroup = this.classGroup + " icon-large";
      this.heightProp = 30;
      this.widthProp = 5;
    }
  },
};
</script>
<style scoped>
.a {
  fill: #2f7bc3;
}
.icon {
  display: flex;
  align-items: center;
  align-content: center;
  background: #f8f8fa 0% 0% no-repeat padding-box;
  border-radius: 100px;
  width: max-content;
}

.icon-small {
  background: #f8f8fa 0% 0% no-repeat padding-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 30px;
}

.icon-large {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 18px;
  padding-bottom: 18px;
}
</style>
