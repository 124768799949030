const LoopView = () => import("@/pages/loops/view.vue");
const LoopInfo = () => import("@/pages/loops/info.vue");
const LoopStats = () => import("@/pages/loops/stats.vue");
const LoopMatch = () => import("@/pages/loops/match.vue");
const LoopResults = () => import("@/pages/loops/results.vue");
const LoopAction = () => import("@/pages/loops/action.vue");
const Loops = () => import("@/pages/loops/index.vue");
const UserMatches = () => import("@/pages/UserMatches.vue");

const ALL_ROLES = ["user", "managed-user", "manager", "admin", "support-agent"];

export default [
  {
    path: "/loops",
    name: "loops-list",
    component: Loops,
    meta: { authorized: ALL_ROLES },
  },
  {
    path: "/loops/create",
    name: "loop-create",
    props: { action: "create" },
    meta: { authorized: ALL_ROLES },
    component: LoopAction,
  },
  {
    path: "/loops/:loopId/edit",
    name: "loop-edit",
    props: route => ({ action: "edit", loopId: route.params.loopId }),
    meta: { authorized: ALL_ROLES },
    component: LoopAction,
  },
  {
    path: "/loops/:loopId/duplicate",
    name: "loop-duplicate",
    props: route => ({ action: "duplicate", loopId: route.params.loopId }),
    meta: { authorized: ALL_ROLES },
    component: LoopAction,
  },
  {
    path: "/loops/:loopId/matches/:matchId",
    props: true,
    name: "match-info",
    meta: { authorized: ALL_ROLES },
    component: LoopMatch,
  },
  {
    path: "/loops/:loopId",
    redirect: { name: "loop-matches" },
    props: true,
    name: "loop-view",
    meta: { authorized: ALL_ROLES },
    component: LoopView,
    children: [
      {
        path: "info",
        name: "loop-info",
        component: LoopInfo,
        meta: { authorized: ALL_ROLES },
      },
      {
        path: "stats",
        name: "loop-stats",
        component: LoopStats,
        meta: { authorized: ALL_ROLES },
      },
      {
        path: "matches",
        name: "loop-matches",
        component: LoopResults,
        meta: { authorized: ALL_ROLES },
      },
    ],
  },
  {
    path: "/newLoop",
    redirect: "loops/create",
  },

  {
    path: "/matches",
    name: "matches",
    meta: { authorized: ["managed-user", "user"] },
    component: UserMatches,
  },

  {
    path: "/matches/:matchId",
    name: "match",
    meta: { authorized: ["managed-user", "user", "admin", "support-agent"] },
    component: UserMatches,
  },

  // Leave this path to support matches in emails
  {
    path: "/match/:matchId",
    name: "match-info-email-compatible",
    component: LoopMatch,
    meta: { authorized: ALL_ROLES },
  },
];
