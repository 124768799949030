<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      :stroke="selected ? '#175c9f' : '#000000'"
      :stroke-width="selected ? '80' : '20'"
    >
      <path
        d="M340 4469 c-160 -31 -299 -173 -330 -339 -14 -72 -14 -3067 0 -3141
 32 -169 170 -307 340 -339 73 -14 4347 -14 4420 0 170 32 308 170 340 339 14
 74 14 3068 0 3142 -32 169 -170 307 -340 339 -67 13 -4365 12 -4430 -1z m4374
 -208 c19 -7 -210 -221 -1517 -1409 -526 -479 -507 -463 -552 -483 -51 -24
 -119 -24 -171 0 -38 17 -508 440 -1628 1465 -378 346 -455 421 -440 427 25 10
 4283 10 4308 0z m-3648 -917 c462 -421 840 -769 842 -773 1 -5 -91 -91 -205
 -192 -288 -255 -641 -580 -1086 -998 -208 -196 -381 -357 -386 -359 -5 -2 -11
 7 -15 20 -8 30 -8 2996 0 3037 4 17 8 31 9 31 2 0 380 -344 841 -766z m3843
 -779 c0 -825 -2 -1510 -5 -1523 -4 -13 -10 -22 -15 -20 -5 2 -178 163 -386
 359 -445 418 -798 743 -1086 998 -114 101 -206 187 -205 192 2 4 381 353 843
 775 763 697 840 764 846 743 4 -13 7 -699 8 -1524z m-2712 -251 c171 -153 278
 -196 426 -173 98 15 170 57 301 174 102 92 120 104 135 93 120 -94 675 -599
 1249 -1137 380 -356 430 -407 410 -413 -13 -3 -984 -6 -2158 -6 -1174 0 -2145
 3 -2158 6 -20 6 31 57 410 413 238 223 516 482 618 575 192 177 639 574 645
 574 2 0 57 -47 122 -106z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
