<template>
  <transition name="page-fade" mode="out-in">
    <app-loader v-if="isLoading" app />
    <component v-else :is="currentLayout">
      <router-view />

      <template v-slot:app>
        <app-info />
        <notifications />
      </template>
    </component>
  </transition>
</template>

<script>
import DefaultLayout from "./layouts/DefaultLayout.vue";
import AuthLayout from "./layouts/AuthLayout.vue";
import AppInfo from "./components/AppInfo.vue";
import { mapGetters } from "vuex";
import { constant } from "./store/constant";
import { isExtensionInstalled } from "./store/modules/user/actions";
const user = constant.user;

export default {
  name: "App",
  components: {
    DefaultLayout,
    AuthLayout,
    AppInfo,
  },
  computed: {
    ...mapGetters("user", { userLoaded: user.getters.userLoaded , isExtensionInstalled: user.getters.isExtensionInstalled}),

    isLoading() {
      return !this.userLoaded && !this.$route.meta.public;
    },

    currentLayout() {
      return `${this.$route.meta.layout || "default"}-layout`;
    },
  },
  async mounted() {
    try {
      this.$amplitude.logEvent("APP_STARTUP");
      this.$store.dispatch(user.actions.IsExtensionInstalled)
    } catch (error) {
      console.error("App.vue error", error);
    }
  },
};
</script>

<style lang="scss">
// page-fade
.page-fade-enter-active,
.page-fade-leave-active {
  transition: all 0.4s ease-out;
}

.page-fade-enter,
.page-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

// negative margins introduced by https://github.com/vuetifyjs/vuetify/issues/12848
// TODO fix
.row {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.row + .row {
  margin-top: 0 !important;
}
</style>
