<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="700">
    <v-card class="pa-6">
      <!-- Pay new subscription -->
      <StripeCheckout
        :cost="newPlanCost"
        :plan="newPlan"
        :trial="false"
        @payment-success="close"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import StripeCheckout from "@/components/subscription_plan/StripeCheckout.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const plans = constant.plans;

export default {
  components: {
    StripeCheckout,
  },

  data() {
    return {
      dialog: false,
      newPlan: -1,
    };
  },

  computed: {
    ...mapGetters("plans", {
      getPlans: plans.getters.GetPlans,
    }),

    pricingPlansData() {
      if (this.$store.getters.isB2C) {
        return this.getPlans?.plans;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },

    newPlanCost() {
      const plan = this.pricingPlansData.find(({ index }) => index === this.newPlan);
      return plan?.cost || 0;
    },
  },

  methods: {
    open(newPlan) {
      this.newPlan = newPlan;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.newPlan = -1;
    },
  },
};
</script>
