import { generateActionTypes } from "@/helpers/utils/arrayUtils";
const Module = "client";

const actionsList = [`fetchClients`, `createClient`, `deleteClient`, `fetchSubscription`, "deleteCV", "manualApplication"];

export const clientDataStatus = {
  successFetchClients: "SuccessFetchClients",
  failureFetchClients: "FailureFetchClients",
  successCreateClients: "SuccessCreateClients",
  failureCreateClients: "FailureCreateClients",
  successDeleteClient: "SuccessDeleteClient",
  failureDeleteClient: "FailureDeleteClient",
  successFetchSubscription: "SuccessFetchSubscription",
  failureFetchSubscription: "FailureFetchSubscription",
};

export const clientDataActions = {
  fetchClients: "fetchClients",
  createClient: "createClient",
  deleteClient: "deleteClient",
  fetchSubscription: "fetchSubscription",
  manualApplication: "manualApplication",
};

export const mutation = {
  setClients: "setClients",
  setState: "setState",
  setLoading: "setLoading",
  setError: "setError",
  setEmailOfUserThatWasDeleted: "setEmailOfUserThatWasDeleted",
  setSubscription: "setSubscription",
};

export const errors = {
  emailExists: "emailExists",
  maxManagedUser: "maxManagedUser",
  unknown: "unknown",
};

export const actions = generateActionTypes(Module, actionsList);
