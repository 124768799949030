<template>
  <v-banner
    v-if="!this.isUserPartner && this.userNumRemainingApplications === 0 && this.userPricingPlan === 1 && showPricingPlans"
    app
    class="warning-banner w-100"
    color="red-accent-3"
    rounded
    shaped
    dark
    small
    :single-line="isDesktop"
    :two-line="!isDesktop"
    elevation="2"
  >
    <template v-slot:icon>
      <v-icon size="24"> md:warning_amber </v-icon>
    </template>

    <div class="text-body-1" :class="{ 'text-truncate': isDesktop }">{{ $t("upgrade-banner-text") }}</div>

    <template v-slot:actions>
      <v-dialog v-model="dialog" max-width="800">
        <template v-slot:activator="{ props }">
          <v-btn variant="outlined" color="white" v-bind="props"> {{ $t("upgrade-now") }}</v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5">
            {{ $t("pricing-plan") }}
          </v-card-title>

          <PricingPlans></PricingPlans>
        </v-card>
      </v-dialog>
    </template>
  </v-banner>
</template>

<script>
import PricingPlans from "@/components/subscription_plan/PricingPlans.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "UpgradeBanner",
  data: () => ({
    dialog: false,
    isDesktop: true,
  }),
  components: {
    PricingPlans,
  },
  watch: {
    dialog(value) {
      this.$amplitude.logEvent("TOGGLE_UPGRADE_BANNER_DIALOG", { show: value });
    },
  },
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    user() {
      return this.getUser;
    },

    userNumRemainingApplications() {
      return this.getUser?.numRemainingApplications;
    },

    userPricingPlan() {
      return this.getUser?.pricingPlan;
    },

    showPricingPlans() {
      return this.$store.getters.configs?.showPricingPlans;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = window.screen.availWidth > 600;
    },
  },
};
</script>
