<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9.441" height="5.781" viewBox="0 0 9.441 5.781">
    <path class="a" d="M1227.484,129.239l4.19-4.19,4.19,4.19" transform="translate(-1226.954 -123.989)" />
  </svg>
</template>

<style scoped>
.a {
  fill: none;
  stroke: #2f7bc3;
  stroke-width: 1.5px;
}
</style>
