import appSDK from "@/client/AppSDK";

export async function updateSmtpSettings(payload) {
  try {
    const res = await appSDK["smtp.update"](payload);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function deleteSmtpSettings(payload) {
  try {
    const res = await appSDK["smtp.delete"](payload);
    return res;
  } catch (e) {
    throw e;
  }
}
