<template>
  <v-icon v-bind="$attrs" :icon="icons[name]"></v-icon>
</template>

<script>
import icons from "@/data/icons";

export default {
  name: "AppIcon",
  data: () => ({
    icons,
  }),
  props: {
    name: {
      // icon name from the ones imported from material design icons
      type: String,
      default: null,
    },
  },
};
</script>
