<template>
  <div class="block" :style="'width:' + width">
    <label class="input-title header5">
      {{ title }} <span class="required">{{ required ? "*" : "" }}</span>
    </label>
    <div :class="inputBlockClass">
      <textarea
        :class="inputClass"
        :placeholder="!uneditable ? placeholder : ''"
        :autocomplete="autocomplete"
        :value="modelValue"
        :disabled="uneditable"
        :id="id"
      ></textarea>
      <span class="icons">
        <slot></slot>
      </span>
    </div>
    <label class="error-message header7">{{ error }}</label>
    <label v-if="errorExample && error" class="error-message-example header7">
      {{ $t("Generic.Common.example", { value: errorExample }) }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
    errorExample: {
      type: String,
      required: false,
      default: "",
    },
    autocomplete: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    uneditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: "100%",
    },
    id: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      inputBlockClass: "input-block",
      inputClass: "header5 input-text-area",
    };
  },

  watch: {
    error(value) {
      if (value) {
        if (!this.inputBlockClass.includes("hasError")) {
          this.inputBlockClass += " hasError";
        }
      } else {
        this.inputBlockClass = this.inputBlockClass.replace(" hasError", "");
      }
    },
    uneditable(value) {
      if (value) {
        this.inputBlockClass += " uneditable-input-block";
        this.inputClass += " uneditable-input";
      } else {
        this.inputBlockClass = this.inputBlockClass.replace(" uneditable-input-block", "");
        this.inputClass = this.inputClass.replace(" uneditable-input", "");
      }
    },
  },

  created() {
    if (this.uneditable) {
      this.inputBlockClass += " uneditable-input-block";
      this.inputClass += " uneditable-input";
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.block {
  display: flex;
  flex-direction: column;
}

.input-title {
  color: #989696;
}

.required {
  color: var(--blue);
}

.input-block {
  margin-top: 7px;
  border: 1px solid #acacaf;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  color: var(--black);
  gap: 10px;
}

.input-block:focus-within {
  border: 1px solid var(--blue);
  border-radius: 6px;
  outline-width: 0;
}

.input-text-area {
  width: 100%;
  height: 100px;
  resize: none;
  border: 0;
  outline-width: 0;
  outline: none;
  padding: 10px;
}

.uneditable-input-block {
  cursor: not-allowed;
  background-color: #f8f8fa;
  border: 0;
}

.uneditable-input {
  cursor: not-allowed;
  border: 0;
}

.hasError {
  border: 1px solid #d8685e;
  border-radius: 6px;
}

.error-message {
  color: #d8685e;
  margin-top: 2px;
}

.error-message-example {
  color: #d8685e;
}

.icons {
  color: #acacaf;
  cursor: pointer;
  display: inline-flex;
}

.icons span {
  margin-left: 5px;
}

.icons span:hover {
  color: #989696 !important;
  outline-width: 0;
}
</style>
