<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    viewBox="0 0 616.000000 575.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,575.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2196 4197 c-57 -16 -128 -91 -143 -150 -10 -36 -13 -293 -13 -1117
0 -824 3 -1081 13 -1117 17 -64 86 -133 150 -151 36 -9 233 -12 817 -12 752 0
770 0 824 21 55 20 91 53 124 113 15 27 18 76 22 398 l5 367 286 288 c273 274
287 291 305 346 15 49 16 64 6 103 -26 95 -80 150 -174 175 -41 11 -55 10
-105 -5 -51 -16 -72 -32 -178 -137 -67 -66 -126 -119 -132 -119 -8 0 -12 91
-15 318 l-3 317 -27 45 c-34 59 -262 280 -313 305 -38 19 -67 20 -725 21 -472
1 -697 -2 -724 -9z m1194 -345 c6 -264 -16 -242 251 -242 l199 0 0 -292 0
-292 -173 -176 -174 -175 -51 -158 c-29 -87 -52 -172 -52 -188 0 -39 32 -69
73 -69 26 1 291 85 360 115 16 7 17 -9 17 -258 0 -246 -1 -266 -19 -288 l-19
-24 -786 0 c-741 0 -786 1 -803 18 -17 17 -18 76 -21 1091 -1 697 1 1083 8
1100 5 15 20 31 32 36 13 5 265 8 588 7 l565 -2 5 -203z m328 72 c82 -82 122
-129 120 -141 -3 -16 -17 -18 -148 -18 l-145 0 -3 134 c-1 74 0 141 3 149 10
28 52 -2 173 -124z m712 -630 c7 -7 14 -30 14 -49 0 -29 -9 -44 -47 -83 l-47
-47 -52 52 -52 53 39 41 c22 22 49 45 60 50 25 10 65 2 85 -17z m-235 -234
l50 -50 -175 -175 c-96 -96 -180 -175 -185 -175 -13 0 -95 82 -95 95 0 10 341
355 350 355 3 0 28 -23 55 -50z m-470 -465 c25 -25 45 -49 45 -54 0 -18 -39
-41 -107 -64 -40 -13 -74 -22 -76 -20 -2 2 7 36 20 76 23 68 46 107 64 107 5
0 29 -20 54 -45z"
      />
      <path
        d="M2383 3450 c-48 -19 -56 -75 -17 -114 l27 -26 547 0 547 0 27 26 c31
32 33 63 7 95 l-19 24 -549 2 c-304 1 -558 -2 -570 -7z"
      />
      <path
        d="M2383 3150 c-48 -20 -56 -75 -17 -114 l27 -26 397 0 397 0 27 26 c31
32 33 63 7 95 l-19 24 -399 2 c-219 1 -408 -2 -420 -7z"
      />
      <path
        d="M2383 2850 c-48 -20 -56 -75 -17 -114 l27 -26 397 0 397 0 27 26 c31
32 33 63 7 95 l-19 24 -399 2 c-219 1 -408 -2 -420 -7z"
      />
      <path
        d="M2383 2550 c-48 -20 -56 -75 -17 -114 l27 -26 397 0 397 0 27 26 c31
32 33 63 7 95 l-19 24 -399 2 c-219 1 -408 -2 -420 -7z"
      />
      <path
        d="M2983 2090 c-48 -20 -56 -75 -17 -114 l27 -26 247 0 247 0 27 26 c31
32 33 63 7 95 -19 24 -20 24 -268 26 -136 1 -258 -2 -270 -7z"
      />
    </g>
  </svg>
</template>
