<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      :stroke="selected ? '#175c9f' : '#000000'"
      :stroke-width="selected ? '50' : '30'"
    >
      <path
        d="M2600 4514 c-369 -41 -655 -201 -865 -484 -59 -80 -148 -261 -171
 -349 -10 -36 -18 -67 -19 -68 -1 -2 -39 13 -85 33 -160 67 -224 79 -420 78
 -146 0 -188 -4 -252 -22 -447 -123 -758 -503 -785 -957 -10 -178 35 -391 109
 -515 66 -109 179 -166 313 -158 126 8 221 72 277 185 46 95 50 171 12 278 -71
 204 -3 390 174 477 60 30 75 33 157 33 82 0 97 -3 157 -33 129 -63 201 -178
 201 -322 0 -110 -27 -170 -119 -272 -127 -139 -183 -255 -206 -420 -19 -143 9
 -273 97 -455 49 -100 92 -144 181 -185 68 -32 198 -32 266 -1 114 52 197 169
 206 291 4 67 -6 108 -59 238 -12 31 -11 33 39 91 60 68 67 105 29 141 -42 40
 -73 30 -144 -45 -35 -38 -68 -78 -74 -91 -18 -45 -10 -107 26 -192 38 -94 42
 -133 20 -188 -54 -128 -210 -161 -309 -64 -46 44 -113 209 -128 311 -24 162
 34 323 168 470 92 102 139 192 154 300 10 73 9 99 -12 196 -37 174 -204 336
 -386 374 -172 36 -338 -14 -463 -138 -149 -149 -192 -336 -123 -541 18 -53 25
 -89 21 -122 -12 -138 -171 -214 -289 -139 -53 34 -86 94 -120 219 -29 106 -31
 325 -4 426 48 179 116 297 245 426 178 176 377 260 619 260 155 0 279 -29 414
 -96 l66 -33 4 -98 c3 -80 8 -108 30 -155 53 -114 165 -198 288 -216 l55 -7 18
 -75 c29 -112 28 -307 -1 -423 -12 -49 -22 -101 -22 -117 0 -53 74 -79 119 -42
 14 11 30 50 47 117 23 86 27 121 27 250 1 139 -8 207 -39 306 -3 12 9 25 39
 42 104 61 165 167 179 311 10 106 28 166 67 227 115 181 339 246 528 155 207
 -101 292 -342 195 -551 -46 -98 -115 -160 -238 -214 -212 -95 -340 -210 -424
 -385 -68 -141 -82 -216 -78 -412 3 -148 5 -163 30 -216 35 -76 104 -142 186
 -181 59 -28 77 -32 152 -31 74 0 94 4 152 31 81 38 146 102 185 181 25 51 28
 70 32 174 2 72 8 121 15 127 6 5 39 20 72 35 288 120 524 371 639 679 23 62
 97 129 173 157 80 30 204 25 282 -11 199 -93 267 -346 142 -529 -69 -101 -135
 -133 -351 -174 -148 -27 -306 -121 -398 -235 -100 -124 -179 -352 -171 -487 8
 -125 72 -220 186 -276 60 -30 76 -33 152 -33 101 0 154 20 225 86 59 54 89
 107 110 190 9 36 20 70 24 77 3 6 40 16 81 23 214 33 406 128 567 282 141 135
 238 301 290 495 24 86 27 116 26 257 0 175 -11 232 -72 390 -38 98 -122 231
 -155 246 -36 17 -73 3 -91 -32 -19 -35 -14 -57 27 -117 45 -65 95 -178 119
 -267 32 -116 32 -327 2 -440 -95 -351 -387 -615 -733 -665 -111 -16 -117 -18
 -154 -50 -37 -33 -45 -49 -76 -157 -24 -83 -57 -128 -115 -154 -87 -40 -185
 -11 -236 69 -37 59 -38 111 -2 238 66 241 233 390 475 424 40 6 91 15 113 21
 186 50 338 226 367 424 35 249 -129 495 -373 560 -105 28 -241 16 -351 -30
 -17 -7 -18 2 -18 111 -1 66 -7 155 -15 199 -8 43 -13 81 -11 82 15 15 171 36
 266 36 155 0 267 -28 407 -99 59 -30 115 -54 125 -54 28 0 68 45 68 76 0 36
 -44 74 -143 122 -220 108 -484 137 -711 77 -32 -9 -60 -14 -61 -13 -1 2 -24
 46 -52 100 -169 323 -472 550 -817 613 -87 15 -239 25 -296 19z m290 -171
 c330 -66 624 -321 739 -639 49 -134 64 -239 58 -394 -6 -151 -31 -258 -92
 -387 -103 -220 -308 -416 -526 -504 -140 -57 -169 -104 -169 -276 0 -105 -18
 -151 -77 -204 -104 -94 -269 -64 -337 60 -25 46 -26 56 -26 188 0 161 13 222
 67 333 27 53 59 97 113 151 76 77 76 77 294 179 98 46 201 150 249 250 46 96
 56 144 57 257 0 269 -184 493 -450 549 -246 52 -510 -81 -619 -311 -33 -70
 -48 -128 -60 -244 -11 -106 -41 -153 -120 -193 -92 -47 -187 -33 -257 38 -58
 57 -68 95 -61 228 13 283 158 553 390 729 161 123 310 181 542 211 50 7 206
 -5 285 -21z"
      />
      <path
        d="M2549 1567 c-98 -41 -160 -97 -206 -187 -25 -50 -28 -65 -28 -160 0
 -93 3 -112 27 -162 70 -149 243 -239 400 -208 124 24 220 97 275 208 25 51 28
 68 28 157 0 93 -2 105 -32 166 -39 79 -102 140 -181 177 -82 38 -204 42 -283
 9z m250 -160 c61 -40 94 -97 99 -168 6 -78 -16 -136 -72 -187 -141 -130 -367
 -29 -366 164 1 138 94 227 231 221 52 -2 75 -8 108 -30z"
      />
      <path
        d="M1719 1277 c-182 -52 -290 -231 -244 -409 40 -160 168 -260 330 -260
 162 0 290 100 331 261 50 199 -99 401 -308 416 -36 3 -82 -1 -109 -8z m178
 -164 c55 -29 97 -101 97 -167 0 -106 -84 -191 -189 -190 -170 3 -254 205 -134
 325 62 62 146 73 226 32z"
      />
      <path
        d="M3493 1276 c-104 -34 -176 -98 -222 -196 -20 -44 -25 -70 -26 -135 0
 -71 4 -88 33 -147 60 -123 169 -190 308 -191 124 -1 241 72 302 188 24 44 27
 61 27 150 0 112 -15 157 -80 233 -74 86 -237 133 -342 98z m148 -148 c80 -27
 129 -94 129 -175 0 -63 -13 -99 -48 -136 -39 -42 -80 -60 -137 -60 -118 -1
 -209 102 -191 216 19 119 137 193 247 155z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
