import * as repo from "@/repositories/prices";
import { mutation, status } from "./data";

export async function fetchPrices({ commit }) {
  try {
    const plans = await repo.getPrices();
    commit(mutation.setPlans, plans);
    commit(mutation.setState, status.SuccessFetchPrices);
  } catch (e) {
    console.error("error in promise prices", e);
    commit(mutation.setState, status.FailureFetchPrices);
  }
}

export async function cancelSubscription({ commit }, payload) {
  try {
    await repo.cancelSubscription(payload);

    commit(mutation.setState, status.SuccessCancelSubscription);
  } catch (e) {
    commit(mutation.setState, status.FailureCancelSubscription);
    throw e;
  }
}

export async function createSubscription({ commit }, payload) {
  try {
    const res = await repo.createSubscription(payload);
    commit(mutation.setState, status.SuccessCreateSubscription);
    return res;
  } catch (e) {
    commit(mutation.setState, status.FailureCreteSubscription);
    throw e;
  }
}

export async function addPromoCode({ commit }, payload) {
  try {
    const code = await repo.setPromoCode(payload);
    commit(mutation.setPromoCode, code);
    commit(mutation.setState, status.SuccessPromoCode);
    commit(mutation.setPromoCodeError, null);
  } catch (e) {
    if (e.responseContent) {
      commit(mutation.setPromoCodeError, e.responseContent);
    }
    commit(mutation.setState, status.FailurePromoCode);
    throw e;
  }
}
