import router from "../router";
import store from "@/store";

// every logged-in route would need meta.authorized, for no-pricing-plan-redirection to work

router.beforeEach((to, from, next) => {
  // TODO explicit support for feature selection
  if (to.meta.partners && !to.meta.partners.includes(process.env.VUE_APP_CONFIGS)) {
    return next(`/login`);
  }

  // If un-authorized route
  if (!to.meta?.authorized) {
    return next();
  }

  const user = store.getters["user/getUser"];

  const role = user.role;

  //TODO: Uncomment for price plan
  //If user doesn't have plan
  if (
    user.role !== "admin" &&
    user.role != "support-agent" &&
    user.role !== "manager" &&
    !user.pricingPlan &&
    store.getters.configs?.requirePricingPlanForNormalUsers
  ) {
    if (to.path === "/setuppricing") {
      return next();
    } else {
      return next("/setuppricing");
    }
  }

  // Redirect to complete profile
  if (user.firstName === "" || user.lastName === "") {
    if (to.path === "/completeProfile") {
      return next();
    } else {
      return next("/completeProfile");
    }
  }

  // If user can access this page
  if (!to.meta?.authorized || to.meta?.authorized.includes(role)) {
    return next();
  }

  // Redirect to unauthorized page
  next(`/not-authorize`);
});