<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      :stroke="selected ? '#175c9f' : '#000000'"
      :stroke-width="selected ? '100' : '50'"
    >
      <path
        d="M132 5109 c-46 -13 -109 -80 -122 -128 -7 -27 -9 -502 -8 -1543 l3
-1503 24 -38 c13 -21 42 -50 64 -65 l41 -27 1095 0 1096 0 38 24 c21 13 50 42
65 64 l27 41 0 1526 0 1526 -27 41 c-15 22 -44 51 -65 64 l-38 24 -1080 2
c-664 1 -1093 -2 -1113 -8z m1928 -1649 l0 -1260 -830 0 -830 0 0 1260 0 1260
830 0 830 0 0 -1260z"
      />
      <path
        d="M2792 5109 c-46 -14 -109 -80 -122 -128 -7 -27 -10 -246 -8 -684 l3
-643 27 -41 c15 -22 44 -51 65 -64 l38 -24 1095 0 1095 0 38 24 c21 13 50 42
65 64 l27 41 0 666 0 666 -27 41 c-15 22 -44 51 -65 64 l-38 24 -1080 2 c-664
1 -1093 -2 -1113 -8z m1928 -789 l0 -400 -830 0 -830 0 0 400 0 400 830 0 830
0 0 -400z"
      />
      <path
        d="M2792 3309 c-46 -13 -109 -80 -122 -128 -7 -27 -9 -502 -8 -1543 l3
-1503 24 -38 c13 -21 42 -50 64 -65 l41 -27 1095 0 1096 0 38 24 c21 13 50 42
65 64 l27 41 0 1526 0 1526 -27 41 c-15 22 -44 51 -65 64 l-38 24 -1080 2
c-664 1 -1093 -2 -1113 -8z m1928 -1649 l0 -1260 -830 0 -830 0 0 1260 0 1260
830 0 830 0 0 -1260z"
      />
      <path
        d="M132 1589 c-46 -14 -109 -80 -122 -128 -7 -27 -10 -246 -8 -684 l3
-643 27 -41 c15 -22 44 -51 65 -64 l38 -24 1095 0 1095 0 38 24 c21 13 50 42
65 64 l27 41 0 666 0 666 -27 41 c-15 22 -44 51 -65 64 l-38 24 -1080 2 c-664
1 -1093 -2 -1113 -8z m1928 -789 l0 -400 -830 0 -830 0 0 400 0 400 830 0 830
0 0 -400z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
