// TODO remove file

import { toCurrency } from "@/helpers/utils/stringUtils";
import i18n from "../../core/i18n";
import xdBike from "@/assets/new-design-icons/Group 23572.svg";
import xdMotorbike from "@/assets/new-design-icons/Group 23573.svg";
import xdCar from "@/assets/new-design-icons/Group 23574.svg";
import store from "@/store/index.js";

const state = {
  fetchedPrices: process.configs?.prices || null,
};

const getters = {
  pricingPlansData(state) {
    const fetchedPrices = state.fetchedPrices;

    if (!fetchedPrices) {
      // TODO block while fetching the first time
      // console.error("prices not fetched yet");
      // store.dispatch("fetchPrices"); // no await
      return [];
    }

    const frontendObject = [
      {
        index: 1,
        name: i18n.t("plans.basic.name"),
        maxLoops: i18n.t("plans.basic.maxLoops"),
        description2: i18n.t("plans.basic.description2"),
        imageUrl: xdBike,
        icon: "looks_one",
      },
      {
        index: 2,
        name: i18n.t("plans.standard.name"),
        maxLoops: i18n.t("plans.standard.maxLoops"),
        description2: i18n.t("plans.standard.description2"),
        imageUrl: xdMotorbike,
        icon: "looks_two",
      },
      {
        index: 3,
        name: i18n.t("plans.premium.name"),
        maxLoops: i18n.t("plans.premium.maxLoops"),
        description2: i18n.t("plans.premium.description2"),
        imageUrl: xdCar,
        icon: "looks_3",
      },
    ];

    return frontendObject.map((frontendItem, index) => {
      const cost = fetchedPrices.plans[index].cost;
      const fullItem = {
        ...frontendItem,
        ...fetchedPrices.plans[index],
        description: `${toCurrency(cost, fetchedPrices.currency)}`,
        currency: fetchedPrices.currency,
      };
      return fullItem;
    });
  },
};

const mutations = {
  setPrices(state, payload) {
    state.fetchedPrices = payload;
  },
};

const actions = {
  async fetchPrices({ commit }) {
    const res = await fetch("https://api.loopcv.com/prices").then(res => res.json());
    commit("setPrices", res);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

// test mode prices
// 2: "price_1ISRIhJt0w4lJiZV51UoN6D8",
// 3: "price_1ISRJKJt0w4lJiZVGYrQyTaY"
