import { defineAsyncComponent } from 'vue';
import { Role } from "@/helpers/enums/eRole";

const Clients = defineAsyncComponent(() => import('@/pages/partners/clients.vue'));
const Managers = defineAsyncComponent(() => import('@/pages/partners/managers.vue'));
const Loops = defineAsyncComponent(() => import('@/pages/partners/loops.vue'));
const LoopAction = defineAsyncComponent(() => import('@/pages/loops/action.vue'));
const LoopView = defineAsyncComponent(() => import('@/pages/loops/view.vue'));
const LoopInfo = defineAsyncComponent(() => import('@/pages/loops/info.vue'));
const LoopStats = defineAsyncComponent(() => import('@/pages/loops/stats.vue'));
const LoopResults = defineAsyncComponent(() => import('@/pages/loops/results.vue'));
const LoopNotes = defineAsyncComponent(() => import('@/pages/partners/LoopNotes.vue'));
const UserMatches = defineAsyncComponent(() => import('@/pages/UserMatches.vue'));
const Billing = defineAsyncComponent(() => import('@/pages/partners/billing.vue'));
const Profile = defineAsyncComponent(() => import("@/pages/Profile.vue"));
const ProfileInfo = defineAsyncComponent(() => import("@/pages/user/Profile/ProfileInfo.vue"));
const SmtpSettings = defineAsyncComponent(() => import("@/components/profile/SmtpSettings.vue"));
const ProfileAccountSettings = defineAsyncComponent(() => import("@/pages/user/Profile/ProfileAccountSettings.vue"));
const ProfileQuestions = defineAsyncComponent(() => import("@/pages/user/Profile/ProfileQuestions.vue"));
const NotificationSettings = defineAsyncComponent(() => import("@/pages/user/Profile/NotificationSettings.vue"));

export default [
  {
    path: "/partner/profile/:clientId?",
    name: "profile-partner",
    redirect: { name: "partner-profile-info" },
    component: Profile,
    meta: { authorized:  [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
    children: [
      {
        path: "info",
        name: "partner-profile-info",
        component: ProfileInfo,
        meta: { authorized:  [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
     
      {
        path: "smtp",
        name: "partner-profile-smtp",
        component: SmtpSettings,
        meta: { authorized:  [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "accountSettings",
        name: "partner-profile-accountSettings",
        component: ProfileAccountSettings,
        meta: { authorized:  [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "questions",
        name: "partner-profile-questions",
        component: ProfileQuestions,
        meta: { authorized:  [Role.MANAGER, Role.ADMIN, Role.SupportAgent]  },
      },
    {
        path: "notifications",
        name: "partner-notifications-settings",
        component: NotificationSettings,
        meta: { authorized:  [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
    ],
  },
  {
    path: "/clients",
    name: "partner-clients",
    component: Clients,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/managers",
    name: "partner-managers",
    component: Managers,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/matches",
    name: "partner-matches",
    component: UserMatches,
    props: true,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops",
    name: "partner-client-loops",
    component: Loops,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops/create",
    name: "partner-loop-create",
    props: route => ({ action: "create", ...route.params }),
    component: LoopAction,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops/:loopId/edit",
    name: "partner-loop-edit",
    props: route => ({ action: "edit", ...route.params }),
    component: LoopAction,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops/:loopId/duplicate",
    name: "partner-loop-duplicate",
    props: route => ({ action: "duplicate", ...route.params }),
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
    component: LoopAction,
  },
  {
    path: "/clients/:clientId/loops/:loopId",
    redirect: { name: "partner-loop-matches" },
    props: true,
    name: "partner-loop-view",
    component: LoopView,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
    children: [
      {
        path: "info",
        name: "partner-loop-info",
        component: LoopInfo,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "notes",
        name: "partner-loop-notes",
        component: LoopNotes,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "stats",
        name: "partner-loop-stats",
        component: LoopStats,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "matches",
        name: "partner-loop-matches",
        component: LoopResults,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
    ],
  },
  {
    path: "/partner-billing",
    name: "partner-billing",
    component: Billing,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },

];
