import { defineAsyncComponent } from 'vue';

const NotFound = defineAsyncComponent(() => import("@/pages/NotFound.vue"));
const NotAuthorized = defineAsyncComponent(() => import("@/pages/NotAuthorized.vue"));

import loop from "./partials/loop";
import user from "./partials/user";
import dashboard from "./partials/dashboard";
import auth from "./partials/auth";
import partner from "./partials/partner";

const notAuthorized = [{ path: "/not-authorize", component: NotAuthorized }];
const notFound = [{ path: '/:catchAll(.*)', component: NotFound }];

export default [loop, user, dashboard, auth, partner, notAuthorized, notFound].flat();
