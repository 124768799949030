<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="5.204" height="8.825" viewBox="0 0 5.204 8.825">
    <path class="a" d="M0,3.63,3.9,0,7.8,3.63" transform="translate(1.024 8.314) rotate(-90)" />
  </svg>
</template>

<style scoped>
.a {
  fill: none;
  stroke: #081929;
  stroke-width: 2px;
}
</style>
