<template>
  <a :href="`mailto:${email}`" target="_blank">{{email}}</a>
</template>

<script>
export default {
	name: 'BaseMailto',
	props: {
		email: {
			type: String,
			default: ''
		}
	}
}
</script>

<style>

</style>