<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M1328 4000 c-211 -37 -375 -182 -433 -382 l-16 -58 -325 0 -325 0
-24 -25 -25 -24 0 -376 0 -376 25 -24 c18 -19 35 -25 68 -25 66 0 166 -35 221
-77 196 -150 196 -446 0 -596 -55 -42 -155 -77 -221 -77 -33 0 -50 -6 -68 -25
l-25 -24 0 -376 0 -376 25 -24 24 -25 1169 0 c1012 0 1171 2 1188 15 16 12 75
14 355 15 397 0 377 -5 390 104 12 99 51 176 120 240 76 71 148 99 249 99 191
0 347 -142 367 -334 5 -47 12 -69 29 -86 l23 -23 381 0 381 0 24 25 25 24 0
1166 0 1166 -25 24 -24 25 -385 0 -385 0 -19 -24 c-12 -16 -22 -49 -26 -88
-13 -132 -112 -263 -234 -308 -68 -26 -186 -29 -248 -6 -137 48 -236 172 -250
315 -5 39 -14 72 -27 88 l-21 26 -677 -7 -677 -8 -7 42 c-10 60 -69 171 -120
228 -116 129 -315 201 -477 172z m219 -182 c108 -40 213 -172 228 -286 10 -78
14 -91 37 -112 21 -19 34 -20 335 -20 l313 0 0 -260 0 -259 -42 -12 c-163 -43
-320 -196 -376 -364 -98 -291 74 -611 367 -686 l51 -13 0 -268 0 -268 -1060 0
-1060 0 0 269 0 269 45 6 c81 13 196 79 266 154 200 212 200 522 0 734 -70 75
-185 141 -266 154 l-45 6 0 269 0 269 319 0 c318 0 320 0 344 23 19 17 27 39
36 95 20 131 89 230 194 282 108 53 205 59 314 18z m1638 -449 c13 -75 79
-190 148 -256 216 -208 546 -201 748 15 65 69 122 174 134 243 l7 39 274 0
274 0 0 -1055 0 -1055 -274 0 -274 0 -7 41 c-14 79 -80 192 -154 261 -210 199
-512 198 -723 -1 -73 -68 -140 -181 -153 -260 l-7 -41 -274 0 -274 0 0 314 c0
372 7 350 -114 371 -104 18 -174 54 -235 120 -65 72 -94 146 -94 245 -1 102
23 167 83 233 62 68 132 106 228 122 64 11 81 19 104 44 l28 31 0 315 0 315
274 0 274 0 7 -41z"
      />
    </g>
  </svg>
</template>
