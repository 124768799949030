<template>
  <v-app dark>
    <v-main>
      <div class="full-page">
        <slot />
      </div>
    </v-main>

    <slot name="app" />
  </v-app>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style>
.full-page {
  height: 98%;
  background-color: var(--off_white);
}
</style>
