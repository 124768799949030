export const chartColors = [
  { backgroundColor: "rgba(255, 99, 132, 0.2)", borderColor: "rgb(255, 99, 132)" }, // soft red
  { backgroundColor: "rgba(132, 99, 255, 0.2)", borderColor: "rgb(132, 99, 255)" }, // light purple
  { backgroundColor: "rgba(99, 132, 255, 0.2)", borderColor: "rgb(99, 132, 255)" }, // cornflower blue
  { backgroundColor: "rgba(255, 99, 177, 0.2)", borderColor: "rgb(255, 99, 177)" }, // pink
  { backgroundColor: "rgba(255, 165, 99, 0.2)", borderColor: "rgb(255, 165, 99)" }, // light orange
  { backgroundColor: "rgba(255, 255, 99, 0.2)", borderColor: "rgb(255, 255, 99)" }, // bright yellow
  { backgroundColor: "rgba(99, 210, 255, 0.2)", borderColor: "rgb(99, 210, 255)" }, // sky blue
  { backgroundColor: "rgba(255, 99, 247, 0.2)", borderColor: "rgb(255, 99, 247)" }, // magenta
];
