<template>
  <div class="text-loader"></div>
</template>

<script>
export default {
  name: "AppTextLoader"
};
</script>

<style>
:root {
  --shimmer-color-alpha: #dae7f200;
  --shimmer-color: #dae7f2;
  --light-color: #f9f9f9;
}

.text-loader {
  display: block;
  margin-bottom: 10px;
  max-width: 500px;
  width: 90%;
  height: 1em;
  border-radius: 2px;
  background: linear-gradient(to right, var(--shimmer-color-alpha) 0, var(--shimmer-color) 30%, var(--shimmer-color) 70%, var(--shimmer-color-alpha) 100%);
  background-color: var(--light-color);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: loader-shimmer 1.5s linear infinite forwards;
}
.text-loader + .text-loader {
  max-width: 200px;
  width: 70%;
}

@keyframes loader-shimmer {
  0% { background-position-x: -200px; }
  100% { background-position-x: 600px; }
}
</style>
