<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="14px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2509 4896 c-25 -7 -58 -22 -75 -33 -16 -12 -277 -271 -580 -575
 -602 -603 -580 -577 -572 -689 10 -142 160 -230 296 -173 25 10 158 136 404
 382 l368 366 0 -1892 c0 -1688 2 -1897 16 -1930 45 -109 173 -161 279 -113 43
 19 100 78 116 118 5 15 9 771 9 1919 0 1042 3 1894 7 1894 4 0 172 -164 373
 -364 211 -212 378 -370 395 -376 48 -18 128 -14 172 9 73 37 123 114 123 190
 0 83 4 79 -584 669 -309 310 -577 572 -596 581 -49 25 -97 31 -151 17z"
      />
    </g>
  </svg>
</template>
