<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#4caf50" stroke="none">
      <path
        d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
-662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
-565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45
498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132
296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180
476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193
-740 235 -147 23 -475 34 -615 20z m468 -401 c488 -53 934 -270 1288 -623 415
-415 634 -943 634 -1526 0 -583 -219 -1111 -634 -1526 -415 -415 -943 -634
-1526 -634 -583 0 -1111 219 -1526 634 -415 415 -634 943 -634 1526 0 583 219
1111 634 1526 349 349 800 569 1276 623 110 12 376 13 488 0z"
      />
      <path
        d="M3512 3478 c-30 -16 -224 -224 -653 -697 l-612 -673 -316 315 c-281
281 -321 317 -361 327 -91 24 -182 -17 -224 -102 -31 -62 -33 -108 -6 -166 25
-54 760 -806 822 -839 49 -27 111 -30 168 -7 32 13 170 159 736 782 473 520
703 781 717 811 72 162 -115 333 -271 249z"
      />
    </g>
  </svg>
</template>
