import axios from "axios"; 
import i18n from "@/core/i18n"; 
import store from "../store/index"; 
import Cookies from "js-cookie"; 
import { USER_TOKEN_COOKIE } from "@/assets/js/constants.js";  

const BaseURL = process.env.VUE_APP_B2B_BASEURL || "https://b2b-api.loopcv.com";  

const eErrorStatus = {   
  UnAuthorized: 401,   
  Forbidden: 403,   
  NotAllowed: 405,   
  Conflict: 409, 
};  

export default function createAxiosInstance({ auth = true, contentType = true, formData = false }) {   
  const headers = {     
    Accept: "application/json",   
  };   

  if (auth) {     
    headers.Authorization = `Bearer ${Cookies.get(USER_TOKEN_COOKIE)}`;   
  }   

  if (contentType && !formData) {     
    headers["Content-Type"] = "application/json";   
  }   

  if (formData) {     
    headers["Content-Type"] = "application/x-www-form-urlencoded";   
  }    

  const encryptedPartnerId = Cookies.get("encryptedPartnerId");   
  if (encryptedPartnerId) {     
    headers["p"] = encryptedPartnerId;   
  }    

  const instance = axios.create({     
    baseURL: BaseURL,     
    headers: headers,   
  });    

  let isRefreshing = false;
  let refreshSubscribers = [];

  const refreshToken = async () => {
    try {
      const currentToken = Cookies.get(USER_TOKEN_COOKIE);
      
      if (!currentToken) {
        throw new Error(i18n.global.t("Generic.Messages.Error.email_password_error", { email: store.getters.configs?.supportEmail }));
      }
      
      const response = await fetch(`${BaseURL}/auth/refreshToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: currentToken }),
      });

      if (!response.ok) {
        throw new Error(`Failed to refresh token. Status: ${response.status}`);
      }

      const data = await response.json();

      if (!data.token) {
        throw new Error("Failed to refresh token.");
      }

      const newToken = data.token;
      Cookies.set(USER_TOKEN_COOKIE, newToken);
      return newToken;
    } catch (error) {
      store.dispatch('logout');
      throw error;
    }
  };

  const requestInterceptor = config => {
    if (process.env.VUE_APP_NODE_ENV === "development") {
      // console.debug(config.method.toUpperCase(), config.url);
      // console.debug("Payload:", config.data);
    }
    
    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"];
    }    
    return config;
  };

  const responseInterceptor = response => {
    if (process.env.VUE_APP_NODE_ENV === "development") {
      // console.debug("Response:", response);
    }
    
    const contentType = response.headers['content-type'];
    if (contentType?.includes('application/csv')) {
      return new Blob([response.data], { type: 'application/csv' });
    }
    
    return response.data;
  };

  const errorInterceptor = async error => {
    const originalRequest = error.config;

    if (originalRequest._retry) {
      return Promise.reject(error);
    }

    if (error?.message?.includes("Failed to fetch")) {
      return;
    }

    if (error?.message?.includes("Network Error")) {
      throw error;
    }

    if (error.response && error.response.status === eErrorStatus.UnAuthorized) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const newToken = await refreshToken();

          instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

          originalRequest._retry = true;
          return instance(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }
    }

    if (error.response && error.response.status === eErrorStatus.Forbidden) {
      error.response.message = i18n.global.t("Generic.Messages.Error.refresh");
    } else if (error.response && error.response.status === eErrorStatus.NotAllowed) {
      error.response.message = i18n.global.t("Generic.Messages.Error.notAllowed");
    } else if (error.code === "ERR_NETWORK") {
      error.response.message = i18n.global.t("Generic.Messages.Error.timeout");
    }
    return Promise.reject(error.response);
  };

  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, errorInterceptor);    

  return instance; 
}