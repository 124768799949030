import { AlertWarningIcon, AlertErrorIcon, AlertSuccessIcon } from "@/assets/fonts/icons";
export default {
  components: { AlertWarningIcon, AlertErrorIcon, AlertSuccessIcon },

  prop: {
    title: {
      type: String,
    },

    text: {
      type: String,
    },

    type: {
      type: String,
      validator(value) {
        return ["success", "warning", "error"].includes(value);
      },
      required: true,
    },
  },

  computed: {
    getTemplate() {
      return `f_alert f_alert_${this.$attrs.type}`;
    },

    getLabelTemplate() {
      return `header5 f_alert_${this.$attrs.type}_label`;
    },
  },
};
