<template>
  <svg width="84px" height="84px" viewBox="0 0 84 84">
    <circle class="border" cx="42" cy="42" r="40" stroke-linecap="round" stroke-width="4" stroke="#000" fill="none"></circle>
    <path
      class="checkmark"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338"
      stroke-width="4"
      stroke="#000"
      fill="none"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "SuccessIcon",
};
</script>
