<template>
  <div class="body">
    <div class="icon-rounded" @click="handleClick">
      <ArrowLeftIcon />
    </div>
    <label class="header5-bold" @click="handleClick">
      {{ this.$t("loop.buttons.previous") }}
    </label>
  </div>
</template>

<script>
import { ArrowLeftIcon } from "@/assets/fonts/icons/index.js";
export default {
  components: {
    ArrowLeftIcon,
  },

  methods: {
    handleClick() {
      this.$emit("previous");
    },
  },
};
</script>

<style scoped>
@import "./previous.css";
</style>
