import i18n from "@/core/i18n";

export function formatDateToLocalMMDDYY(date) {
  const parsedDate = new Date(date);

  if (!date && isNaN(parsedDate.getTime())) {
    return "-";
  }

  return parsedDate.toLocaleDateString('en', {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formattedDateYYYYMMDD(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;

  const dateStr = year + "-" + month + "-" + day;
  return dateStr.toString();
}

export function formattedDateMMDDYYYY(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
}
