<template>
  <div class="body" v-if="total > itemsPerPage">
    <div class="float-left"></div>
    <div class="pagination" v-if="pages <= 5 && pages > 0">
      <div class="items previous" v-if="selected != 1" @click="previous">
        <ArrowLeftIcon />
      </div>

      <div
        :class="i == selected ? 'items header6-semi-bold selected' : 'items header6-semi-bold '"
        v-for="i in step"
        :key="i"
        @click="handleItem(i)"
      >
        {{ i }}
      </div>
      <div class="items next" v-if="selected != pages && total > 0" @click="next">
        <ArrowRightIcon />
      </div>
    </div>

    <div class="pagination" v-if="pages > 5 && pages > 0">
      <div class="items previous" v-if="selected != 1" @click="previous">
        <ArrowLeftIcon />
      </div>
      <div
        v-if="1 != this.selected && this.selected - 2 * this.paginationItemLR > 0"
        class="items header6-semi-bold"
        @click="handleItem(1)"
      >
        1
      </div>
      <div
        v-if="this.step != this.selected && this.selected - 2 * this.paginationItemLR > 0"
        class="item-continue header6-semi-bold"
      >
        ...
      </div>
      <div
        :class="i == selected ? 'items header6-semi-bold selected' : 'items header6-semi-bold '"
        v-for="i in this.step"
        :key="i"
        @click="handleItem(i)"
      >
        {{ i }}
      </div>
      <div v-if="this.selected + this.paginationItemLR < this.pages" class="item-continue header6-semi-bold">...</div>
      <div
        v-if="this.selected + this.paginationItemLR < this.pages"
        :class="pages == selected ? 'items header6-semi-bold selected' : 'items header6-semi-bold '"
        @click="handleItem(pages)"
      >
        {{ pages }}
      </div>
      <div class="items next" v-if="selected != pages && total > 0" @click="next">
        <ArrowRightIcon />
      </div>
    </div>

    <div class="items-per-page header6" @click="handleItemPerPage" v-if="total > 0">
      <div class="value">
        <label class="header6"> {{ this.$t("Table.rows_per_pages") }} {{ itemsPerPage }}</label>
        <ArrowDownIcon v-if="!showItemsPerPage" /><ArrowUpIcon v-if="showItemsPerPage" />
      </div>
      <div class="items-per-page-options" v-if="showItemsPerPage">
        <div class="option" v-for="(item, index) in itemsPerPageOption" :key="index" @click="() => changePerPageOption(item)">
          <label :class="item == itemsPerPage ? 'itemPerPage-selected header6-semi-bold' : 'header6-semi-bold'"
            >{{ item }} <SelectedIcon v-if="item == itemsPerPage"
          /></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectedIcon from "@/assets/fonts/Selected.vue";
import { ArrowDownIcon, ArrowUpIcon, ArrowLeftIcon, ArrowRightIcon } from "@/assets/fonts/icons/index";

export default {
  components: {
    ArrowDownIcon,
    ArrowUpIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    SelectedIcon,
  },
  props: {
    selected: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    itemsPerPageOption: {
      type: Array,
      required: true,
      validator: value => {
        if (Array.isArray(value)) {
          return value.every(item => typeof item === "number");
        } else {
          return false;
        }
      },
    },
  },

  data() {
    return {
      pages: 0,
      showItemsPerPage: false,
      paginationItemLR: 2,
    };
  },

  created() {
    // @ts-ignore
    this.init();

    if (window.innerWidth < 900) {
      // @ts-ignore
      this.paginationItemLR = 1;
    }
  },

  watch: {
    total() {
      // @ts-ignore
      this.init();
    },

    itemsPerPage() {
      // @ts-ignore
      this.init();
    },
  },

  computed: {
    step() {
      const numberArray = [];
      let start, stop;

      if (this.pages <= 5) {
        start = 1;
        stop = this.pages;
        // @ts-ignore
      } else if (this.selected + this.paginationItemLR < this.pages && this.selected - this.paginationItemLR > 0) {
        // @ts-ignore
        stop = this.selected + this.paginationItemLR;
        // @ts-ignore
        start = stop - 2 * this.paginationItemLR;
        // @ts-ignore
      } else if (this.selected + this.paginationItemLR < this.pages && this.selected - this.paginationItemLR <= 0) {
        start = 1;
        // @ts-ignore
        stop = this.selected + this.paginationItemLR;
      } else {
        stop = this.pages;
        // @ts-ignore
        start = this.pages - 2 * this.paginationItemLR;
      }

      for (let i = start; i <= stop; i++) {
        numberArray.push(i);
      }

      return numberArray;
    },
  },

  methods: {
    init() {
      // @ts-ignore
      this.pages = Math.floor(this.total / this.itemsPerPage);
      // @ts-ignore
      if (this.total % this.itemsPerPage > 0) {
        // @ts-ignore
        this.pages++;
      }
    },

    previous() {
      // @ts-ignore
      this.$emit("page-change", this.selected - 1);
    },

    next() {
      // @ts-ignore
      this.$emit("page-change", this.selected + 1);
    },

    handleItem(value) {
      this.$emit("page-change", value);
    },

    handleItemPerPage() {
      // @ts-ignore
      this.showItemsPerPage = !this.showItemsPerPage;
    },

    changePerPageOption(value) {
      this.$emit("page-option-change", value);
    },
  },
};
</script>

<style scoped>
.body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
}

.items {
  width: 42px;
  height: 42px;
  background-color: #f8f8fa;
  border-radius: 100%;
  margin-left: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-continue {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  margin-right: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next,
.previous {
  width: 42px;
}

.selected {
  background-color: var(--black) !important;
  color: var(--white) !important;
}

/* #region item per page */
.items-per-page {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 28px;
  padding-inline: 14px;
  padding-block: 8px;
  cursor: pointer;
  background-color: var(--white);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.items-per-page .value {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.items-per-page .value label {
  margin-right: 10px;
  cursor: pointer;
  color: #35383c;
}

.items-per-page-options {
  position: absolute;
  background-color: var(--white);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  bottom: 112%;
  right: 5%;
  width: 90%;
}

.items-per-page-options .option {
  padding: 10px;
}

.items-per-page-options .option:hover {
  background-color: #f8f8fa;
}

.items-per-page-options .option .itemPerPage-selected {
  color: var(--blue);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* #endregion */

@media screen and (max-width: 700px) {
  .items {
    width: 30px;
    height: 30px;
  }

  .items-per-page {
    padding-inline: 8px;
    padding-block: 2px;
    height: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .left {
    display: none;
  }

  .body {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 477px) {
  .body {
    flex-direction: column-reverse;
    margin-top: 5px !important;
  }

  .pagination {
    margin-top: 5px;
  }
}
</style>
