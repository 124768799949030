<template>
  <div>
    <div class="buttonGroup">
      <div :class="checkBtn" @click="this.handleClickBtn">
        <div class="checkedBtn" v-if="checked"></div>
      </div>
      <label class="button2 label" v-if="title && title != ''">{{ title }}</label>
    </div>
    <label class="error-label header7" v-if="error && error != ''">{{ error }}</label>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
    checked: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      checkBtn: "checkButton",
    };
  },

  watch: {
    error(value) {
      if (value != "") {
        this.checkBtn = this.checkBtn + " errorButton";
      } else {
        this.checkBtn = "checkButton";
      }
    },
  },

  created() {
    if (this.error && this.error != "") {
      this.checkBtn = this.checkBtn + " errorButton";
    }
  },
  methods: {
    handleClickBtn() {
      this.$emit("input", !this.checked);
    },
  },
};
</script>

<style scoped>
@import "./checkButton.css";
</style>

<!--
  <CheckButton
   :title="'Login'"
   :error="'This fiend is mandatory'"
   :checked="checked"
   @input="handleInput"
  />
-->
