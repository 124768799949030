<template>
  <v-navigation-drawer width="231" v-model="drawer" floating>
    <div class="d-flex align-center" style="height: 64px">
      <div class="sidebar-logo-text" v-if="logoText">{{ logoText }}</div>

      <img v-else :src="logo" :alt="`${brand} logo`" class="sidebar-logo" />
    </div>

    <v-list nav>
      <v-list-item v-for="item in filteredItems" :key="item.title" link :to="item.link">
        <template v-slot:prepend>
          <component :is="item.icon" :selected="selected(item) ? true : false"
            :style="item.margin ? `margin-top:${item.margin}` : ''" class="mr-4" />
        </template>

        <div :class="selected(item) ? 'selected text-body-1' : 'text-body-1'">{{ $t(item.title) }}</div>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2 pb-5">
        <v-btn v-if="!$store.getters.isWhitelabel" href="https://www.loopcv.pro/help" variant="outlined" target="_blank"
          class="sidebar-item" rounded height="48" color="primary">
          <!-- <app-icon name="helpCircleOutline" /> -->
          {{ $t("sidebar.help") }}</v-btn>
        <v-btn exact variant="outlined" rounded to="/settings" class="sidebar-item" height="48" color="primary"
          v-if="isAdmin">
          {{ $t("page.settings") }}
        </v-btn>
        <LocaleSwitcher ></LocaleSwitcher>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import {
  DashboardIcon,
  ApplicationIcon,
  InboxIcon,
  MatchesIcon,
  BoardIcon,
  LoopIcon,
  MyLoopIcon,
  ManagerIcon,
  ClientIcon,
  QuestionIcon,
} from "@/assets/fonts/pages/index.js";
import { Role } from "@/helpers/enums/eRole";
import EventBus from "@/plugins/eventsBus";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

const user = constant.user;

export default {
  name: "TheSidebar",
  components: {
    ApplicationIcon,
    InboxIcon,
    MatchesIcon,
    BoardIcon,
    LoopIcon,
    MyLoopIcon,
    DashboardIcon,
    ManagerIcon,
    ClientIcon,
    QuestionIcon,
    LocaleSwitcher
  },
  data() {
    return {
      items: [
        {
          title: "sidebar.overview",
          link: "/overview",
          icon: "DashboardIcon",
        },
        {
          title: "sidebar.automations",
          link: "/loops",
          icon: "MyLoopIcon",
          authorized: [Role.USER, Role.MANAGED_USER],
        },

        {
          title: "sidebar.clients",
          link: "/clients",
          icon: "ClientIcon",
          authorized: [Role.ADMIN, Role.MANAGER, Role.SupportAgent],
        },
        {
          title: "sidebar.board",
          link: "/board",
          icon: "BoardIcon",
          authorized: [Role.USER, Role.MANAGED_USER],
        },
        {
          title: "sidebar.managers",
          link: "/managers",
          icon: "ManagerIcon",
          authorized: [Role.ADMIN, Role.SupportAgent],
        },
        {
          title: "sidebar.allMatches",
          link: "/matches",
          icon: "MatchesIcon",
          authorized: [Role.USER, Role.MANAGED_USER],
        },
        {
          title: "sidebar.my_applications",
          link: "/myApplications",
          icon: "ApplicationIcon",
          authorized: [Role.USER, Role.MANAGED_USER],
        },
        ,
        {
          title: "sidebar.questions",
          link: "/questions",
          icon: "QuestionIcon",
          authorized: [Role.USER, Role.MANAGED_USER],
          margin: "-3px",
        },
        {
          title: "sidebar.inbox",
          link: "/inbox",
          icon: "InboxIcon",
          authorized: [Role.USER, Role.MANAGED_USER],
        },
      ],
      drawer: false,
    };
  },
  created() {
    const savedDrawerState = localStorage.getItem("drawerState");
    if (savedDrawerState !== null) {
      this.drawer = JSON.parse(savedDrawerState);
    }

    EventBus.on("toggle-sidebar", () => {
      this.drawer = !this.drawer;
      this.saveDrawerState();
    });
  },
  watch: {
    drawer(newVal) {
      this.saveDrawerState();
    },
  },
  methods: {
    saveDrawerState() {
      localStorage.setItem("drawerState", JSON.stringify(this.drawer));
    }
  },
  computed: {
    ...mapGetters("user", {
      getUser: user.getters.getUser,
      userLoaded: user.getters.userLoaded,
      isUserPartner: user.getters.isUserPartner,
    }),

    user() {
      return this.getUser;
    },

    logo() {
      return this.$store.getters.logoUrl;
    },

    logoText() {
      return this.$store.getters.configs.logoText;
    },

    brand() {
      return this.$store.getters.brand;
    },

    primaryColor() {
      return this.$store.getters.primaryColor;
    },

    role() {
      return this.isUserPartner ? "partner" : "user";
    },

    isAdmin() {
      return this.user?.role === "admin" || this.user?.role === "support-agent";
    },

    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },

    filteredItems() {
      if (!this.userLoaded) {
        return [];
      }

      return this.items.filter(item => {
        if (this.onlyEmail == true) {
          if (item.link == "/myApplications") {
            return false;
          }
          if (this.onlyEmail == true) {
            if (item.link == "/questions") {
              return false;
            }
          }
        }
        if (item.link === "/inbox" && !this.$store.getters.configs?.showInboxPage) {
          return false;
        }

        if (item.link === "/myApplications" && this.$store.getters.configs?.disableApplications) {
          return false;
        }

        if (item.link === "/questions" && this.$store.getters.configs?.disableApplications) {
          return false;
        }

        if (item.link === "/managers" && !this.$store.getters.configs?.showManagerAdministration) {
          return false;
        }

        if ((item.link === "/loops/create" || item.link === "/loops") && this.$store.getters.configs?.hideLoops) {
          return false;
        }

        if (!item.authorized) return true;
        return item.authorized.includes(this.user?.role);
      });
    },

    selected() {
      return item => this.$route.path == item.link;
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer__border {
  right: 1px !important;
}

.sidebar-item {
  height: 52px;
  width: 199px;
}

.sidebar-logo {
  width: 138px;
  margin-top: 2px;
  margin-left: 16px;
  // padding-right: 50px;
}

.sidebar-logo-text {
  margin-top: 2px;
  margin-left: 24px;
  font-size: 18px;
}

.selected {
  color: var(--black);
}

.sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
