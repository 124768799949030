<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 19.029 17.406">
    <g transform="translate(-0.923 -5.788)" :stroke="selected ? '#175c9f' : '#000000'" :stroke-width="selected ? '120' : '70'">
      <path
        :fill="selected ? '#175c9f' : '#000000'"
        stroke-width="0"
        d="M16.681,16.679a3.046,3.046,0,0,0-2.04.8l-3.324-2V11.971a3.065,3.065,0,1,0-1.533,0v3.506l-3.324,2a3.142,3.142,0,1,0,.847,1.278l3.244-1.946,3.244,1.946a3.065,3.065,0,1,0,2.887-2.073ZM9.018,9.015a1.533,1.533,0,1,1,1.533,1.533A1.533,1.533,0,0,1,9.018,9.015ZM4.42,21.277a1.533,1.533,0,1,1,1.533-1.533A1.533,1.533,0,0,1,4.42,21.277Zm12.261,0a1.533,1.533,0,1,1,1.533-1.533A1.533,1.533,0,0,1,16.681,21.277Z"
        transform="translate(0)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
