<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2947 5087 c-39 -37 -53 -85 -42 -144 5 -29 31 -62 132 -165 l126
-128 -334 0 c-369 0 -504 -9 -687 -46 -307 -61 -623 -210 -872 -411 -97 -78
-279 -267 -353 -365 -289 -386 -442 -867 -424 -1333 12 -310 79 -567 220 -854
74 -148 102 -176 183 -176 79 0 141 54 150 131 5 33 -4 58 -59 169 -162 326
-223 649 -186 983 84 768 646 1399 1390 1561 155 33 303 42 648 39 l324 -3
-120 -125 c-66 -69 -125 -135 -131 -147 -31 -57 -2 -145 60 -183 39 -24 107
-26 146 -6 15 8 145 133 289 278 227 228 262 268 268 301 15 77 2 96 -260 364
-135 138 -260 261 -279 272 -24 15 -50 21 -94 21 -55 0 -64 -3 -95 -33z"
      />
      <path
        d="M4170 3662 c-48 -24 -80 -75 -80 -129 0 -33 14 -72 61 -165 69 -138
127 -304 161 -458 19 -91 22 -135 23 -330 0 -196 -3 -240 -23 -338 -34 -168
-83 -310 -162 -467 -88 -175 -175 -298 -310 -439 -256 -266 -544 -428 -901
-505 -150 -32 -289 -41 -644 -41 l-329 0 126 128 c99 100 128 136 137 168 18
66 -7 129 -64 164 -45 28 -128 25 -166 -6 -13 -10 -140 -136 -281 -280 l-258
-260 0 -53 c0 -28 6 -64 14 -79 8 -15 133 -145 278 -289 229 -228 268 -262
301 -269 110 -20 201 75 177 183 -7 28 -37 64 -136 164 l-126 127 413 5 c439
6 507 12 716 66 715 185 1291 767 1478 1495 47 184 65 328 65 521 0 333 -68
621 -217 920 -61 122 -83 151 -130 171 -43 19 -81 18 -123 -4z"
      />
      <path
        d="M2500 3603 c-19 -10 -45 -33 -57 -52 -20 -29 -23 -47 -23 -130 l0
-97 -70 -11 c-89 -14 -165 -51 -235 -115 -94 -86 -145 -201 -145 -327 0 -156
77 -296 211 -382 86 -55 123 -62 366 -68 203 -6 224 -8 249 -27 50 -37 68 -70
68 -124 0 -56 -20 -91 -74 -127 -33 -23 -37 -23 -369 -23 -300 0 -340 -2 -371
-18 -107 -53 -107 -211 0 -264 29 -15 63 -18 203 -18 l167 0 0 -99 c0 -85 3
-102 23 -132 61 -91 181 -89 251 4 16 22 21 47 24 125 l4 98 64 11 c195 34
342 181 375 376 30 178 -71 381 -229 461 -93 47 -150 56 -357 56 -166 0 -195
2 -225 19 -101 53 -108 196 -13 258 24 16 62 18 379 23 336 5 354 6 380 26 49
36 67 70 68 123 1 54 -17 88 -68 125 -25 19 -44 21 -201 24 l-173 3 -4 99 c-3
106 -14 131 -70 173 -37 28 -105 32 -148 10z"
      />
    </g>
  </svg>
</template>
