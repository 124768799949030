import components from "../components/index";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(app) {
    components.forEach(comp => {
      app.component(comp.name, comp);
    });
  }
};

export default GlobalComponents;
