<template>
  <div :class="body">
    <label :class="label"> {{ title }}</label>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      validator: value => {
        if (typeof value === "string") {
          return ["purple", "green", "blue", "orange", "yellow", "red"].indexOf(value) !== -1;
        } else {
          return false;
        }
      },
    },
  },
  data() {
    return {
      body: "chip",
      label: "header7",
    };
  },

  created() {
    if (this.color) {
      this.body = this.body + ` ${this.color}-body`;
      this.label = this.label + ` ${this.color}-label`;
    }
  },
};
</script>

<style scoped>
.chip {
  border: 1px solid var(--black);
  border-radius: 35px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: fit-content;
  display: flex;
  align-items: center;
}

.purple-body {
  border: 1px solid rgba(127, 94, 152, 1) !important;
}

.purple-label {
  color: rgba(127, 94, 152, 1) !important;
}

.green-body {
  border: 1px solid #19a59f !important;
}

.green-label {
  color: #19a59f !important;
}

.blue-body {
  border: 1px solid var(--blue) !important;
}

.blue-label {
  color: var(--blue) !important;
}

.orange-body {
  border: 1px solid #e7a01f !important;
}

.orange-label {
  color: #e7a01f;
}

.yellow-body {
  border: 1px solid #e5c04d !important;
}

.yellow-label {
  color: #e5c04d;
}

.red-body {
  border: 1px solid #d8685e !important;
}

.red-label {
  color: #d8685e !important;
}
</style>
