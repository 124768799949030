<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.235" height="10.889" viewBox="0 0 14.235 10.889">
    <path class="a" d="M-8445,4419.14l4.232,4.23,8.235-8.237" transform="translate(8445.886 -4414.25)" />
  </svg>
</template>

<style scoped>
.a {
  fill: none;
  stroke: #2f7bc3;
  stroke-width: 2.5px;
}
</style>
