<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      stroke="none"
    >
      <path
        d="M810 5105 c-222 -50 -418 -228 -490 -445 -33 -100 -38 -253 -12 -358
62 -244 241 -426 482 -489 79 -20 233 -22 310 -4 63 16 179 68 230 104 l35 25
111 -152 112 -151 -68 -71 c-397 -418 -384 -1080 28 -1480 l65 -63 -40 -48
c-22 -26 -160 -194 -308 -373 -147 -179 -271 -329 -275 -333 -5 -5 -37 5 -71
21 -104 50 -179 65 -299 60 -165 -7 -298 -64 -409 -178 -270 -275 -226 -725
92 -938 108 -71 177 -93 319 -99 101 -5 126 -2 190 17 202 61 348 200 414 395
55 164 41 322 -42 484 -24 47 -44 86 -44 87 0 2 144 177 321 391 206 249 327
387 337 386 10 -1 55 -17 102 -37 326 -135 729 -83 1014 131 32 25 63 47 70
49 10 4 562 -579 629 -663 17 -22 17 -23 -23 -82 -102 -150 -144 -288 -143
-476 0 -111 4 -143 27 -218 14 -49 38 -112 52 -140 147 -291 462 -474 784
-454 264 17 495 151 638 370 60 91 105 203 122 303 18 103 8 290 -20 379 -88
277 -302 486 -575 561 -93 25 -287 30 -381 9 -90 -20 -199 -63 -255 -99 -21
-14 -42 -26 -47 -26 -9 0 -645 684 -650 700 -2 5 11 30 28 55 50 73 117 222
140 313 61 243 38 495 -66 720 -19 42 -31 79 -27 83 17 14 493 329 499 329 3
0 29 -23 58 -50 218 -214 541 -277 829 -162 108 44 182 95 272 187 164 168
244 400 215 618 -14 106 -35 175 -76 262 -90 189 -256 334 -462 403 -70 24
-92 26 -237 27 -172 0 -226 -11 -350 -73 -353 -175 -526 -614 -385 -973 11
-27 20 -52 20 -54 0 -5 -506 -345 -513 -345 -1 0 -29 25 -62 57 -188 177 -406
275 -666 298 -157 14 -375 -28 -526 -102 -40 -20 -75 -34 -77 -32 -6 8 -202
274 -224 305 l-21 31 29 55 c70 129 90 330 49 478 -78 282 -322 475 -614 486
-64 2 -123 -1 -165 -11z m210 -206 c274 -43 443 -316 360 -580 -31 -98 -29
-98 -103 -25 l-66 65 20 50 c42 113 17 234 -66 317 -153 153 -391 111 -484
-86 -34 -72 -35 -162 -2 -235 20 -46 21 -50 5 -62 -32 -23 -69 -59 -91 -87
-12 -15 -27 -25 -32 -22 -18 12 -53 121 -58 186 -21 251 174 470 437 489 8 0
44 -4 80 -10z m3434 -134 c75 -20 178 -74 238 -125 57 -49 127 -154 158 -235
28 -75 38 -231 20 -313 -12 -59 -75 -202 -88 -202 -4 0 -16 16 -26 35 -19 34
-82 98 -139 140 l-27 20 27 55 c22 43 29 72 31 135 5 111 -21 180 -97 256 -77
76 -146 103 -256 97 -133 -7 -233 -75 -292 -196 -23 -48 -27 -70 -27 -142 -1
-76 3 -92 30 -147 l32 -61 -54 -37 c-46 -32 -80 -68 -128 -139 -12 -18 -15
-16 -41 28 -64 109 -89 266 -62 392 45 208 208 383 413 439 68 18 217 18 288
0z m-3445 -181 c24 -20 31 -34 31 -63 0 -95 -121 -125 -163 -40 -21 45 -8 92
32 114 39 21 66 18 100 -11z m3385 -195 c76 -66 63 -169 -26 -215 -61 -32
-142 -4 -172 60 -21 44 -20 73 2 114 41 76 134 96 196 41z m-3326 -204 c43
-19 79 -50 98 -87 8 -15 14 -29 14 -31 0 -3 -22 -14 -48 -25 -62 -27 -169 -44
-224 -36 -67 9 -169 45 -175 60 -9 24 43 90 89 113 54 26 188 30 246 6z m3360
-268 c90 -24 163 -91 178 -163 6 -29 3 -32 -55 -58 -159 -71 -321 -71 -484 1
-54 24 -58 28 -52 52 17 71 95 146 173 167 63 17 176 17 240 1z m-1932 -257
c463 -116 740 -605 603 -1065 -12 -38 -32 -92 -46 -120 -27 -54 -105 -175
-113 -175 -3 0 -20 30 -39 68 -43 83 -163 206 -238 242 -29 14 -52 29 -53 33
0 5 13 25 30 45 106 133 114 345 17 494 -102 157 -276 232 -453 195 -95 -20
-158 -54 -223 -118 -160 -161 -174 -402 -34 -586 l25 -32 -49 -25 c-109 -55
-200 -149 -259 -265 l-27 -54 -29 34 c-39 46 -123 219 -145 299 -25 92 -25
318 1 415 40 157 152 338 266 434 121 102 286 178 435 201 85 13 237 4 331
-20z m-143 -494 c115 -35 184 -151 157 -265 -32 -135 -159 -208 -295 -170 -53
15 -129 91 -144 144 -36 129 29 252 151 290 56 18 75 18 131 1z m66 -696 c163
-23 304 -152 336 -306 6 -30 4 -33 -57 -66 -256 -141 -559 -141 -818 -2 l-67
37 9 39 c33 149 164 271 318 297 74 12 201 13 279 1z m2001 -1061 c264 -72
449 -314 450 -585 0 -111 -46 -262 -103 -338 -13 -17 -15 -16 -39 26 -29 48
-105 123 -154 152 l-31 19 33 62 c93 180 21 391 -165 483 -48 23 -68 27 -146
27 -77 0 -98 -4 -147 -27 -76 -35 -138 -95 -173 -165 -52 -106 -44 -260 18
-347 20 -28 20 -30 3 -40 -55 -32 -120 -93 -158 -146 l-42 -60 -28 45 c-70
112 -99 274 -74 412 41 234 209 418 441 484 86 25 222 24 315 -2z m-3597 -309
c83 -41 148 -108 190 -198 29 -61 32 -76 32 -162 0 -76 -5 -105 -22 -143 l-22
-48 -54 53 -54 52 19 48 c39 102 13 213 -67 294 -64 64 -130 89 -220 82 -115
-10 -213 -87 -245 -193 -17 -55 -14 -143 6 -194 9 -23 15 -43 13 -44 -3 -1
-28 -24 -56 -51 l-52 -49 -15 38 c-61 145 -26 326 85 436 86 87 175 120 305
115 78 -3 100 -8 157 -36z m3501 -64 c69 -29 100 -107 72 -181 -43 -112 -186
-126 -254 -25 -19 29 -23 45 -20 87 9 103 105 160 202 119z m-3627 -188 c47
-44 16 -118 -49 -118 -42 0 -68 25 -68 67 0 65 69 96 117 51z m3740 -351 c71
-38 125 -101 147 -173 6 -18 -3 -25 -63 -53 -170 -78 -346 -79 -517 -1 -66 29
-66 30 -60 62 14 69 83 143 162 173 57 21 287 16 331 -8z m-3677 -19 c24 -13
53 -36 64 -51 21 -29 21 -29 1 -43 -66 -49 -236 -57 -328 -16 l-49 22 19 32
c28 50 92 78 178 78 54 0 81 -5 115 -22z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
