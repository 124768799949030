<template>
  <div class="body">
    <div class="round">
      <InfoIcon :large="true" />
    </div>
    <label class="header4">{{ title }}</label>
  </div>
</template>

<script>
import { InfoIcon } from "@/assets/fonts/icons/index";
export default {
  components: {
    InfoIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.body {
  margin-inline: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 47px;
  padding-top: 47px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 25px;
  padding-inline: 20px;
}

.round {
  background-color: #f8f8fa;
  padding-block: 15px;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: fit-content;
}

.body label {
  margin-top: 30px;
  color: var(--black);
}

@media screen and (max-width: 600px) {
  .body {
    width: 100%;
  }
}
</style>
