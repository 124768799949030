<template>
  <div class="stripe stripe-checkout cell example" id="stripe-checkout">
    <p>
      {{ freeForever ? $t("plans.eligible") : $t("plans.addPaymentDetails") }}
    </p>
    <div v-if="isSubmitting" class="progress-circular-container">
      <v-progress-circular :width="4" indeterminate color="green"></v-progress-circular>
    </div>
    <form v-show="!isSubmitting" @submit="this.handleFormSubmit">
      <div v-if="!freeForever" class="mt-6">
        <div data-locale-reversible>
          <div class="row">
            <div class="field">
              <input
                id="stripe-checkout-address"
                data-tid="elements_examples.form.address_placeholder"
                class="input empty"
                type="text"
                placeholder="185 Berry St"
                required="true"
                autocomplete="address-line1"
              />
              <label for="stripe-checkout-address" data-tid="elements_examples.form.address_label">{{
                $t("plans.address")
              }}</label>
              <div class="baseline"></div>
            </div>
          </div>
          <div class="row" data-locale-reversible>
            <div class="field half-width">
              <input
                id="stripe-checkout-city"
                data-tid="elements_examples.form.city_placeholder"
                class="input empty"
                type="text"
                placeholder="San Francisco"
                required="true"
                autocomplete="address-level2"
              />
              <label for="stripe-checkout-city" data-tid="elements_examples.form.city_label">{{ $t("plans.city") }}</label>
              <div class="baseline"></div>
            </div>
            <div class="field half-width">
              <input
                id="stripe-checkout-state"
                data-tid="elements_examples.form.state_placeholder"
                class="input empty"
                type="text"
                placeholder="CA"
                required="true"
                autocomplete="address-level1"
              />
              <label for="stripe-checkout-state" data-tid="elements_examples.form.state_label">{{ $t("plans.state") }}</label>
              <div class="baseline"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="field half-width">
            <select
              name="color"
              id="stripe-checkout-country"
              data-tid="elements_examples.form.country_placeholder"
              class="input empty"
              required="true"
              autocomplete="country"
            >
              <option disabled selected value>
                <span style="color: #cfd7df">{{ this.$t("Generic.Common.select_country") }}</span>
              </option>
              <option v-for="country in countries" :value="country.code">
                {{ country.name }}
              </option>
            </select>
            <label for="stripe-checkout-country"></label>
            <div class="baseline"></div>
          </div>
          <div class="field half-width">
            <input
              id="stripe-checkout-zip"
              data-tid="elements_examples.form.postal_code_placeholder"
              class="input empty"
              type="text"
              placeholder="94107"
              required="true"
              autocomplete="postal-code"
            />
            <label for="stripe-checkout-zip" data-tid="elements_examples.form.postal_code_label">{{ $t("plans.zip") }}</label>
            <div class="baseline"></div>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <div id="stripe-checkout-card-number" class="input empty"></div>
            <label for="stripe-checkout-card-number" data-tid="elements_examples.form.card_number_label">{{
              $t("plans.cardNumber")
            }}</label>
            <div class="baseline"></div>
          </div>
        </div>
        <div class="row">
          <div class="field half-width">
            <div id="stripe-checkout-card-expiry" class="input empty"></div>
            <label for="stripe-checkout-card-expiry" data-tid="elements_examples.form.card_expiry_label">{{
              $t("plans.expiration")
            }}</label>
            <div class="baseline"></div>
          </div>
          <div class="field half-width">
            <div id="stripe-checkout-card-cvc" class="input empty"></div>
            <label for="stripe-checkout-card-cvc" data-tid="elements_examples.form.card_cvc_label">{{
              $t("plans.cvc")
            }}</label>
            <div class="baseline"></div>
          </div>
        </div>
      </div>
      <!-- Checkbox for Refund Policy -->
      <div class="terms">
        <CheckButton @input="value => handleInput(value, 'refundPolicy')" :checked="this.model.refundPolicy" />
        <div class="terms-text">
          <span>{{ this.$t("Auth.Common.agree_terms")}}</span>
          <a target="_blank" href="https://www.loopcv.pro/questions/refund/">{{ this.$t("Auth.Common.refund") }}</a>
        </div>
      </div>
      <div class="terms-error header7">
        {{ this.errors.refundPolicy }}
      </div>
      <button type="submit" data-tid="elements_examples.form.pay_button">
        {{ priceAfterDiscount === 0 ? $t("plans.startForFree") : $t("plans.payX", [formattedPriceAfterDiscount]) }}
      </button>
      <div class="error" role="alert">
        <ErrorIcon />
        <span class="message"></span>
      </div>
    </form>

    <div class="success">
      <div class="icon">
        <SuccessIcon />
      </div>
      <div class="success-messages-block">
        <h3 class="title" data-tid="elements_examples.success.title">
          {{ $t("plans.paymentSuccessful") }}
        </h3>
        <p class="message">
          <span data-tid="elements_examples.success.message">{{ $t("plans.thanks", { brand: $store.getters.brand }) }}</span>
        </p>
      </div>
    </div>

    <div class="text-caption centerText">
      <!-- DISCOUNT FOR THE FIRST MONTH -->
      <div v-if="captionLabel">
        <p v-t="'plans.youWillBeChargedXFirstMonth'" v-if="coupon && coupon.duration !== 'forever'">
          <b>{{ formattedPriceAfterDiscount }}</b>
        </p>
        <!-- FOREVER FREE -->
        <p v-t="'plans.youWillBeChargedForever'" v-else-if="coupon && coupon.duration === 'forever' && coupon.percent_off === 100"></p>
        <p v-t="'plans.youWillBeChargedX'" v-else>
          <b>{{ formattedPriceAfterDiscount }}</b>
        </p>
      </div>

      <!-- TRIAL -->
      <p v-if="trial" v-html="$t('plans.15dayTrial')"></p>
      
      <!-- ALTERNATIVE PAYMENT -->
      <p>
        {{ this.$t("plans.alternativePayment") }}
        <a
          class="here_btn"
          target="_blank"
          @click="this.handleCheckoutClick"
          style="cursor: pointer; text-decoration: underline"
        >
          {{ this.$t("Generic.Common.here") }}
        </a>
      </p>

      <br/>

      <p>{{ $t("plans.stripe") }}</p>

      <!-- ADD NEW PROMO CODE -->
      <p
        v-if="!this.promoCode && !this.successFullPayment"
        @click="this.showPromoCodeDialog"
        style="cursor: pointer; text-decoration: underline"
      >
        {{ $t("plans.addPromoCode") }}
      </p>

      <p v-if="this.promoCode">{{ $t("plans.promoCodeIsApplied", [this.promoCode?.code]) }}</p>

      <!-- ERROR -->
      <p v-if="this.promoCodeError" style="color: red">{{ this.promoCodeError }}</p>
    </div>
    <AddPromoCodeVue ref="promoCodeDialog" />
  </div>
</template>

<script>
import "@/assets/css/stripe-base.css";
import appSDK from "@/client/AppSDK";
import { toCurrency } from "@/helpers/utils/stringUtils.js";
import { loadStripe } from "@stripe/stripe-js/pure";
import countries from "@/assets/countries.json";
import AddPromoCodeVue from "./modals/AddPromoCode.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import ReloadIcon from "@/assets/images/svg/reload.vue";
import ErrorIcon from "@/assets/images/svg/error.vue";
import SuccessIcon from "@/assets/images/svg/success.vue";
import { CheckButton } from "@/framework/index";
import EventBus from "@/plugins/eventsBus";

const plans = constant.plans;
const user = constant.user;

loadStripe.setLoadParameters({ advancedFraudSignals: false });

export default {
  name: "StripeCheckout",
  props: {
    cost: Number,
    trial: { type: Boolean, default: false },
    plan: Number,
  },

  components: {
    AddPromoCodeVue,
    ReloadIcon,
    ErrorIcon,
    SuccessIcon,
    CheckButton,
  },

  data() {
    return {
      countries,
      savedErrors: {},
      formName: "stripe-checkout",
      promoError: null,
      captionLabel: true,
      successFullPayment: false,
      model: {
        refundPolicy: false,
      },
      errors: {
        refundPolicy: "",
      },
    };
  },

  computed: {
    ...mapGetters("plans", {
      getPlans: plans.getters.GetPlans,
      getStatus: plans.getters.GetStatus,
      getError: plans.getters.GetError,
      getPromoCode: plans.getters.GetPromoCode,
      getPromoCodeError: plans.getters.GetPromoCodeError,
    }),

    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },

    status() {
      return this.getStatus;
    },

    freeForever() {
      return this.priceAfterDiscount === 0 && this.coupon?.duration === "forever";
    },

    pricingPlansData() {
      if (this.$store.getters.isB2C) {
        return this.getPlans?.plans;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },

    currentPlanData() {
      if (this.plan == 1) {
        console.log("======= plan:1 =======");
      }
      return this.pricingPlansData.find(item => item.index === this.plan);
    },

    formattedPriceAfterDiscount() {
      if (!this.currentPlanData) {
        return "-";
      }
      if (this.$store.getters.isB2C) {
        return toCurrency(this.priceAfterDiscount.toFixed(2), this.getPlans.currency);
      } else {
        return toCurrency(this.priceAfterDiscount.toFixed(2), this.currentPlanData.currency);
      }
    },

    priceAfterDiscount() {
      if (this.coupon) {
        return this.coupon.amount_off
          ? this.cost - this.coupon.amount_off
          : (this.cost * (100 - this.coupon.percent_off)) / 100;
      } else {
        return this.cost;
      }
    },

    promoCode() {
      return this.getPromoCode || null;
    },

    coupon() {
      return this.promoCode?.coupon || null;
    },

    promoCodeError() {
      return this.getPromoCodeError;
    },
  },

  async mounted() {
    // @ts-ignore
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    this.$nextTick(this.initStripeElements);
  },

  methods: {
    showPromoCodeDialog() {
      // @ts-ignore
      this.$refs.promoCodeDialog.open();
    },

    // @ts-ignore
    initStripeElements() {
      const elements = this.stripe.elements({
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
          },
        ],
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        // @ts-ignore
        locale: window.__exampleLocale,
      });

      // Floating labels
      const inputs = document.querySelectorAll(".cell .input");
      Array.prototype.forEach.call(inputs, function (input) {
        input.addEventListener("focus", function () {
          input.classList.add("focused");
        });
        input.addEventListener("blur", function () {
          input.classList.remove("focused");
        });
        input.addEventListener("keyup", function () {
          if (input.value.length === 0) {
            input.classList.add("empty");
          } else {
            input.classList.remove("empty");
          }
        });
      });

      const elementStyles = {
        base: {
          color: "#32325D",
          fontWeight: 500,
          fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
          fontSize: "16px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#CFD7DF",
          },
          ":-webkit-autofill": {
            color: "#e39f48",
          },
        },
        invalid: {
          color: "#E25950",

          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      };

      const elementClasses = {
        focus: "focused",
        empty: "empty",
        invalid: "invalid",
      };

      const cardNumber = elements.create("cardNumber", {
        style: elementStyles,
        classes: elementClasses,
      });
      const cardExpiry = elements.create("cardExpiry", {
        style: elementStyles,
        classes: elementClasses,
      });
      const cardCvc = elements.create("cardCvc", {
        style: elementStyles,
        classes: elementClasses,
      });

      this.$nextTick(() => {
        cardNumber.mount("#stripe-checkout-card-number");
        cardExpiry.mount("#stripe-checkout-card-expiry");
        cardCvc.mount("#stripe-checkout-card-cvc");
      });

      this.registerElements([cardNumber, cardExpiry, cardCvc]);
    },

    registerElements(elements) {
      // @ts-ignore
      this.elements = elements;

      // @ts-ignore
      this.example = document.querySelector("." + this.formName);
      // @ts-ignore
      this.form = this.example?.querySelector("form");
      // @ts-ignore
      this.error = this.form?.querySelector(".error");
      // @ts-ignore
      this.errorMessage = this.error?.querySelector(".message");

      // Listen for errors from each Element, and show error messages in the UI.
      elements.forEach((element, idx) => element.on("change", e => this.handleElementChange(e, idx)));
    },

    handleElementChange(event, idx) {
      if (event.error) {
        this.$notify({
          message: event.error.message,
          type: "danger",
        });
        this.savedErrors[idx] = event.error.message;
      } else {
        this.savedErrors[idx] = null;

        // Loop over the saved errors and find the first one, if any.
        const nextError = Object.keys(this.savedErrors)
          .sort()
          .reduce((maybeFoundError, key) => {
            return maybeFoundError || this.savedErrors[key];
          }, null);

        if (nextError) {
          // Now that they've fixed the current error, show another one.
          this.$notify({
            message: nextError,
            type: "danger",
          });
        }
      }
    },

    handleFromReset(e) {
      e.preventDefault();
      // Resetting the form (instead of setting the value to `''` for each input)
      // helps us clear webkit autofill styles.
      this.form.reset();

      // Clear each Element.
      this.elements.forEach(element => element.clear());

      // Reset error state as well.
      this.$notify({
        message: this.$t("Generic.Messages.Error.form_reset"),
        type: "info",
      });
    },

    handleFormSubmitFree() {
      this.example.classList.add("submitting");

      this.submitPayment(null)
        .then(() => {
          this.example.classList.remove("submitting");
          this.example.classList.add("submitted");
          this.$notify({
            message: this.$t("plans.Common.success_payment"),
            type: "success",
          });
        })
        .catch(e => {
          console.error(e);
          this.enableInputs();
          this.example.classList.remove("submitting");
          this.$notify({
            message: e.message || this.$t("Generic.Messages.Error.common_error"),
            type: "danger",
          });
        });
    },

    async handleFormSubmit(e) {
      try {
        e.preventDefault();

        if (this.freeForever) {
          this.handleFormSubmitFree();
          return;
        }

        // Trigger HTML5 validation UI on the form if any of the inputs fail
        // validation.
        let plainInputsValid = true;
        Array.prototype.forEach.call(this.form.querySelectorAll("input"), function (input) {
          if (input.checkValidity && !input.checkValidity()) {
            plainInputsValid = false;
            return;
          }
        });
        Array.prototype.forEach.call(this.form.querySelectorAll("select"), function (input) {
          if (input.checkValidity && !input.checkValidity()) {
            plainInputsValid = false;
            return;
          }
        });
        if (!plainInputsValid) {
          this.triggerBrowserValidation();
          return;
        }

        // Check if the refund policy is accepted
        if (!this.model.refundPolicy) {
          this.errors.refundPolicy = this.$t("Auth.Error.disagree_refund_policy");
          return;
        } else {
          this.errors.refundPolicy = "";
        }

        // Show a loading screen...
        this.isSubmitting = true;  
        this.example.classList.add("submitting");

        // Disable all inputs.
        this.disableInputs();

        // Gather additional customer data we may have collected in our form.
        const address1 = this.form.querySelector("#" + this.formName + "-address");
        const city = this.form.querySelector("#" + this.formName + "-city");
        const state = this.form.querySelector("#" + this.formName + "-state");
        const zip = this.form.querySelector("#" + this.formName + "-zip");
        const country = this.form.querySelector("#" + this.formName + "-country");
        const additionalData = {
          address_line1: address1 ? address1.value : undefined,
          address_city: city ? city.value : undefined,
          address_state: state ? state.value : undefined,
          address_zip: zip ? zip.value : undefined,
        };
        if (country.value) {
          additionalData.address_country = country ? country.value : undefined;
        }

        console.log(additionalData);

        // Use Stripe.js to create a token. We only need to pass in one Element
        // from the Element group in order to create a token. We can also pass
        // in the additional customer data we collected in our form.
        await this.submitPayment({
          type: "card",
          card: this.elements[0],
          billing_details: {
            address: {
              city: additionalData.address_city,
              line1: additionalData.address_line1,
              state: additionalData.address_state,
              postal_code: additionalData.address_zip,
              country: additionalData.address_country,
            },
          },
        });
        this.example.classList.remove("submitting");
        this.isSubmitting = false;  
        this.example.classList.add("submitted");
        this.$emit('payment-success');
        return;
      } catch (e) {
        // Stop loading!
        console.error(e);
        this.enableInputs();
        this.isSubmitting = false;  
        this.example.classList.remove("submitting");
        this.$notify({
          message: e.responseContent?.error?.message || e.responseContent || e,
          type: "danger",
        });
      }
    },

    async submitPayment(paymentMethodInfo) {
      try {
        const priceId = this.currentPlanData.priceId;
        const postData = {
          priceId,
          plan: this.plan,
          promoCode: this.promoCode?.code,
        };

        if (!postData.priceId) {
          console.error("======= postData " + JSON.stringify(postData) + "==========");
        }

        const { subscriptionId, clientSecret } = await this.$store.dispatch(plans.actions.CreateSubscription, postData);


        if (clientSecret) {
          // Create payment method and confirm payment intent.
          const result = await this.stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodInfo,
          });

          if (result.error) {
            console.error(result.error);
            throw new Error(`${result.error.message}`);
          }

          setTimeout(async () => {
            await this.$store.dispatch(user.actions.RefreshUserData);
          }, 5000);

          this.$notify({
            message: this.$t("plans.Common.success_payment"),
            type: "success",
          });
          this.$emit("payment-success");
          EventBus.emit("payment-success", this.plan);
          await this.$store.dispatch(user.actions.UpdateUserData, {pricingPlan: this.plan});
          this.$amplitude.logEvent("CREATE_SUBSCRIPTION_SUCCESS", {
            priceId,
            plan: this.plan,
            promoCode: this.promoCode,
          });
          this.successFullPayment = true;
        } else if (!clientSecret && postData.promoCode) {
          setTimeout(async () => {
            await this.$store.dispatch(user.actions.RefreshUserData);
          }, 5000);

          this.$notify({
            message: this.$t("plans.Common.success_payment"),
            type: "success",
          });
          this.$emit("payment-success");
          EventBus.emit("payment-success", this.plan);
          await this.$store.dispatch(user.actions.UpdateUserData, {pricingPlan: this.plan});
          this.$amplitude.logEvent("CREATE_SUBSCRIPTION_SUCCESS", {
            priceId,
            plan: this.plan,
            promoCode: this.promoCode,
          });
          this.successFullPayment = true;
        } else {
          this.$notify({
            message: "The clientSecret is null",
            type: "danger",
          });
        }
      } catch (e) {
        console.error(e);
        this.$notify({
          message: this.$t("Generic.Messages.Error.common"),
          type: "danger",
        });
        throw e;
      }
    },

    triggerBrowserValidation() {
      // The only way to trigger HTML5 form validation UI is to fake a user submit
      // event.
      const submit = document.createElement("input");
      submit.type = "submit";
      submit.style.display = "none";

      this.form.appendChild(submit);
      submit.click();
      submit.remove();
    },

    enableInputs() {
      Array.prototype.forEach.call(
        this.form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel'], select"),
        function (input) {
          input.removeAttribute("disabled");
        },
      );
    },

    disableInputs() {
      Array.prototype.forEach.call(
        this.form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel'], select"),
        function (input) {
          input.setAttribute("disabled", "true");
        },
      );
    },

    getBaseUrl() {
      return window.location.origin;
    },

    async handleCheckoutClick() {
      const priceId = this.currentPlanData.priceId;
      const body = {
        priceId: priceId,
        baseUrl: this.getBaseUrl()
      };

      try {
        const response = await appSDK["stripe.checkout"](body);

        window.open(response.url, '_blank');
      } catch (e) {
        console.error(e);
        this.$notify({
          message: this.$t("Generic.Messages.Error.common"),
          type: "danger",
        });
        throw e;
      }
    },

    // Handle Form
    handleInput(value, name) {
      this.model[name] = value;
    },
  },
};
</script>

<style lang="scss">
.stripe-checkout {
  * {
    font-family: Source Code Pro, Consolas, Menlo, monospace;
    font-size: 16px;
    font-weight: 500;
  }

  .row {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 5px 10px;
  }

  .field {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 10px;
  }

  .field.half-width {
    width: 50%;
  }

  .field.quarter-width {
    width: calc(25% - 10px);
  }

  .baseline {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #cfd7df;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
  }

  .terms-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    margin-left: 8px;
  }

  .terms-text span {
    margin-right: 8px;
  }

  .terms-error {
    color: #d8685e;
    margin-top: 11px;
  }

  label {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 8px;
    color: #cfd7df;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    pointer-events: none;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    text-align: left;
  }

  .input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
    border: 0;
  }

  .centerText {
    text-align: center;
  }

  .input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .input.StripeElement {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
  }

  .input.focused,
  .input:not(.empty) {
    opacity: 1;
  }

  .input.focused::-webkit-input-placeholder,
  .input:not(.empty)::-webkit-input-placeholder {
    color: #cfd7df;
  }

  .input.focused::-moz-placeholder,
  .input:not(.empty)::-moz-placeholder {
    color: #cfd7df;
  }

  .input.focused:-ms-input-placeholder,
  .input:not(.empty):-ms-input-placeholder {
    color: #cfd7df;
  }

  .input.focused + label,
  .input:not(.empty) + label {
    color: #aab7c4;
    transform: scale(0.85) translateY(-25px);
    cursor: default;
  }

  .input.focused + label {
    color: #24b47e;
  }

  .input.invalid + label {
    color: #ffa27b;
  }

  .input.focused + label + .baseline {
    background-color: #24b47e;
  }

  .input.focused.invalid + label + .baseline {
    background-color: #e25950;
  }

  input,
  button,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  input,
  button {
    -webkit-animation: 1ms void-animation-out;
  }

  button {
    display: block;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #24b47e;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
  }

  .error svg {
    margin-top: 0 !important;
  }

  .error svg .base {
    fill: #e25950;
  }

  .error svg .glyph {
    fill: #fff;
  }

  .error .message {
    color: #e25950;
  }

  .success .icon .border {
    stroke: #abe9d2;
  }

  .success .icon .checkmark {
    stroke: #24b47e;
  }

  .success .icon {
    margin: 10px 0px;
  }
  .success .title {
    color: #32325d;
  }

  .success .message {
    color: #8898aa;
    font-size: 13px !important;
  }

  .success .reset path {
    fill: #24b47e;
  }

  .caption {
    margin-top: 40px;
    text-align: center;
    p {
      margin-top: 0 !important;
      margin-bottom: 5px !important;
    }
  }

  .success-messages-block {
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
}

.stripe-checkout.submitting .success .icon {
  margin-bottom: 150px !important;
}

// Add styles for the progress circular
.progress-circular-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

// override vuetify styles
.v-application .stripe-checkout {
  .success,
  .error {
    background-color: initial !important;
    border-color: initial !important;
  }
}
</style>
