import AuthSDK from "@/client/AuthSDK";
import appSDK from "@/client/AppSDK";
import api from "@/core/axios";

export async function sessionRestore(payload) {
  try {
    const res = await AuthSDK["auth.session"](payload);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function signUp(payload) {
  try {
    const res = await api({ auth: false }).post("/auth/signup", {
      firstname: payload.firstName,
      lastname: payload.lastName,
      email: payload.email,
      password: payload.password,
      partnerId: payload.partnerId || null,
    });
    return res;
  } catch (e) {
    throw e;
  }
}

export async function partnerSignUp(payload) {
  try {
    const res = await api({ auth: false }).post("/auth/partner", {
      firstname: payload.firstName,
      lastname: payload.lastName,
      email: payload.email,
      password: payload.password,
      company: payload.company,
    });
    return res;
  } catch (e) {
    throw e;
  }
}

export async function signIn(payload) {
  try {
    const res = await api({ auth: false }).post("/auth/login", {
      email: payload.email,
      password: payload.password,
    });
    return res;
  } catch (e) {
    throw e;
  }
}

export async function googleLogin(payload) {
  try {
    const res = await api({ auth: false }).post("/auth/google", {
      token: payload.token,
      partnerId: payload.partnerId,
    });
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getAuthData() {
  try {
    const res = await api({}).get("/dashboard/get-info");
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getUserInfo() {
  try {
    const res = await api({}).get("/dashboard/user");
    return res;
  } catch (e) {
    throw e;
  }
}

export async function colaberrySignUp(payload) {
  try {
    const res = await appSDK["colaberry.signup"](payload);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function dynamicRegister(payload) {
  try {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    const res = await api({ auth: false, formData: true }).post("/formUser", formData);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function forgotPassword(email) {
  try {
    const res = await api({ auth: false }).post("/auth/forgot", { email: email });
    return res;
  } catch (e) {
    throw e;
  }
}

export async function resetPassword(payload) {
  try {
    return await AuthSDK["password.reset"]({
      sessionId: payload.sessionId,
      email: payload.email,
      password: payload.password,
    });
  } catch (e) {
    throw e;
  }
}

export async function updatePassword(password) {
  try {
    return await AuthSDK["password.update"]({ password: password });
  } catch (e) {
    throw e;
  }
}

export async function deleteUser() {
  try {
    return await appSDK["user.delete"]();
  } catch (e) {
    throw e;
  }
}

export async function updateInfo(payload) {
  try {
    if(payload.clientId) {
      return await api({}).put(`/partner/user/${payload.clientId}`, payload);
    }
    return await api({}).put("/dashboard/user", payload);
  } catch (e) {
    throw e;
  }
}

export async function setDefaultCv(cvId) {
  try {
    return await appSDK["cvs.setDefault"](cvId);
  } catch (e) {
    throw e;
  }
}

export async function getCvs(clientId) {
  try {
    return await appSDK["cvs.list"](clientId);
  } catch (e) {
    throw e;
  }
}

export async function uploadCv(file, clientId) {
  try {
    return await appSDK["cvs.upload"](file, clientId);
  } catch (e) {
    throw e;
  }
}

export async function deleteCV(userId, cvId) {
  try {
    return await appSDK["cvs.delete"](userId, cvId);
  } catch (e) {
    throw e;
  }
}

export async function getOverviewStats() {
  try {
    return api({}).get("/dashboard/overview");
  } catch (e) {
    throw e;
  }
}
