<template>
  <div class="body">
    <div class="round">
      <SuccessIcon :large="true" />
    </div>
    <label class="header4" v-html="label"></label>
    <div class="buttons">
      <slot />
    </div>
  </div>
</template>

<script>
import { SuccessIcon } from "@/assets/fonts/icons/index";
export default {
  components: {
    SuccessIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      label: "",
    };
  },

  mounted() {
    this.label = this.title.replace("\\n", "<br>");
  },
};
</script>

<style scoped>
.body {
  margin-inline: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 47px;
  padding-top: 47px;
  width: 50%;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 25px;
  padding-inline: 15px;
}

.round {
  background-color: #f8f8fa;
  padding-block: 15px;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: fit-content;
}

.body label {
  margin-top: 30px;
  color: var(--black);
  text-align: center;
}

br {
  display: block;
  margin-top: 2px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
