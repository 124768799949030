<template>
  <div class="no-data-card">
    <img src="/img/no_data.svg" alt="No data" class="no-data-card-image" />
    <span class="no-data-card-title">No data found</span>
  </div>
</template>

<style>
.no-data-card {
  display: flex;
  flex-direction: column;
  padding: 40px 10px;
}
.no-data-card-image {
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.no-data-card-title {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}
</style>

<script>
export default {
  name: "AppNotFound"
};
</script>
