<template>
  <div class="component">
    <div class="table">
      <div class="table-header" v-if="headerData && headerData.length">
        <div
          class="table-header-cell"
          v-for="(header, index) in headerData"
          :key="index"
          :style="showHeader({ header, index }) ? 'display:none' : ''"
          @click="sortable(header, index)"
        >
          <label
            :class="header.hasOwnProperty('sortable') ? 'header6-semi-bold pointer' : 'header6-semi-bold'"
            @mouseover="handleMouseOver(index)"
            @mouseleave="handleMouseLeave(index)"
          >
            {{ header.value }}
            <ArrowUpTableIcon v-if="arrowUp(index)" />
            <ArrowDownTableIcon v-if="arrowDown(index)" />
          </label>
        </div>
      </div>
      <div class="table-data" :style="selectedData ? 'cursor:pointer' : ''">
        <div class="table-row" v-for="(row, rowIndex) in tableData" :key="rowIndex" @click="() => handleSelectValue(row)">
          <div
            class="table-cell"
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            :style="!showElement({ row, cell }) ? 'display:none' : ''"
          >
            <span v-if="showElement({ row, cell })"> {{ cell }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowUpTableIcon, ArrowDownTableIcon } from "@/assets/fonts/icons/index.js";
import SelectedIcon from "@/assets/fonts/Selected.vue";

export default {
  components: {
    ArrowUpTableIcon,
    ArrowDownTableIcon,
    SelectedIcon,
  },
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
      validator: value => Array.isArray(value) && value.every(item => typeof item === "object"),
    },
    data: {
      type: Array,
      default: () => [],
      validator: value => Array.isArray(value) && value.every(item => typeof item === "object"),
    },
    selectedData: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      tableData: [],
      headerData: [],
    };
  },
  computed: {
    arrowUp() {
      return index => this.headerData[index]?.sortable && this.headerData[index]?.sortBy === "async";
    },
    arrowDown() {
      return index => this.headerData[index]?.sortable && this.headerData[index]?.sortBy === "desc";
    },
    showElement() {
      return ({ row, cell }) => {
        for (let key in row) {
          if (row[key] === cell) {
            const headerObject = this.headerData.find(element => element.name === key);
            return headerObject?.visible !== false;
          }
        }
        return true;
      };
    },
    showHeader() {
      return ({ header }) => header?.visible === false;
    },
  },
  mounted() {
    this.initializeHeaderData();
    this.initializeTableData();
  },
  watch: {
    data: {
      handler(newData) {
        this.initializeTableData();
      },
      deep: true,
    },
    headers: {
      handler(newHeaders) {
        this.initializeHeaderData();
        this.initializeTableData();
      },
      deep: true,
    },
  },
  methods: {
    initializeHeaderData() {
      this.headerData = this.headers || [];
    },
    initializeTableData() {
      this.tableData = this.data.map(row => {
        let formattedRow = {};
        this.headers.forEach(header => {
          formattedRow[header.name] = row[header.name];
        });
        return formattedRow;
      });
    },
    sortable(header, index) {
      if (header?.sortable) {
        const sortOrder = header.sortBy === "async" ? "desc" : "async";
        this.headerData = this.headerData.map((hdr, idx) => ({
          ...hdr,
          sortBy: idx === index ? sortOrder : undefined,
        }));
        this.sortTableData(header.name, sortOrder);
        this.$forceUpdate();
      }
    },
    sortTableData(sortingItem, sortBy) {
      this.tableData.sort((a, b) => {
        if (sortBy === "async") {
          return a[sortingItem] > b[sortingItem] ? 1 : -1;
        } else {
          return a[sortingItem] < b[sortingItem] ? 1 : -1;
        }
      });
    },
    handleMouseOver(index) {
      if (this.headerData[index]) {
        this.headerData[index].sortBy = "desc";
        this.$forceUpdate();
      }
    },
    handleMouseLeave(index) {
      if (this.headerData[index]) {
        delete this.headerData[index].sortBy;
        this.$forceUpdate();
      }
    },
    handleSelectValue(value) {
      if (this.selectedData) {
        this.$emit("selected", value);
      }
    },
  },
};
</script>

<style scoped>
.component {
  overflow-y: auto;
}
.pointer {
  cursor: pointer;
}
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.table-header {
  display: table-header-group;
  border-bottom: 1px solid #acacaf;
  background-color: #e5e5ea;
}
.table-header-cell {
  display: table-cell;
  padding: 10px;
  color: var(--black);
}
.table-header-cell label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-header-cell label svg {
  margin-left: 2px;
}
.table-data {
  display: table-row-group;
  max-height: 50%;
}
.table-row {
  display: table-row;
  border-bottom: 1px solid #e5e5ea;
}
.table-row:hover {
  background-color: #f8f8fa;
  box-shadow: 0px 0px 3px 0px grey;
}
.table-cell {
  display: table-cell;
  padding: 10px;
  color: var(--black);
}
</style>
