<template>
  <div
    class="loader-wrapper"
    :class="{ 'loader-wrapper--app': app }"
    :style="{ color: primaryColor }"
  >
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
  props: {
    app: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
  },
};
</script>

<style lang="sass">
$loader-width: 4px

.loader-wrapper
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  z-index: 1000
  width: 100%
  height: 100%
  min-height: 200px
  padding: 50px 0

.loader-wrapper--app
  min-height: 100vh

.loader
  position: absolute
  left: calc(50% - 32px)
  width: 64px
  height: 64px
  border-radius: 50%
  perspective: 800px
  color: inherit

.inner
  position: absolute
  box-sizing: border-box
  width: 100%
  height: 100%
  border-radius: 50%
  color: inherit

.inner.one
  left: 0%
  top: 0%
  animation: rotate-one 1s linear infinite
  border-bottom: $loader-width solid currentColor

.inner.two
  right: 0%
  top: 0%
  animation: rotate-two 1s linear infinite
  border-right: $loader-width solid currentColor

.inner.three
  right: 0%
  bottom: 0%
  animation: rotate-three 1s linear infinite
  border-top: $loader-width solid currentColor

@keyframes rotate-one
  0%
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg)

  100%
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg)

@keyframes rotate-two
  0%
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg)

  100%
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg)

@keyframes rotate-three
  0%
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg)

  100%
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg)
</style>