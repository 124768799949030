import { generateActionTypes } from "@/helpers/utils/arrayUtils";
import { isExtensionInstalled } from "./actions";
const Module = "user";

export const status = {
  SuccessRestoreSession: "Success_RestoreSession",
  FailureRestoreSession: "Failure_RestoreSession",

  SuccessSignOut: "Success_SignOut",
  FailureSignOut: "Failure_SignOut",

  SuccessRefreshUserData: "Success_RefreshUserData",
  FailureRefreshUserData: "Failure_RefreshUserData",

  SuccessUpdateUserData: "Success_UpdateUserData",
  FailureUpdateUserData: "Failure_UpdateUserData",

  SuccessResetPassword: "SuccessResetPassword",
  FailureResetPassword: "FailureResetPassword",

  ProgressUpdatePassword: "Progress_UpdatePassword",
  SuccessUpdatePassword: "Success_UpdatePassword",
  FailureUpdatePassword: "Failure_UpdatePassword",

  ProgressColaberrySignIn: "Progress_Colaberry_SignIn",
  SuccessColaberrySignIn: "Success_Colaberry_SignIn",
  FailureColaberrySignIn: "Failure_Colaberry_SignIn",
};

const actionsList = [
  //#region AUTH
  `colaberrySignUp`,
  "dynamicRegister",
  "sessionRestore",
  "signUp",
  "signIn",
  "googleSignIn",
  "signOut",
  "auth",
  "refreshUserData",
  `updatePassword`,
  `forgotPassword`,
  `resetPassword`,
  `deleteUser`,
  `updateInfo`,
  //#endregion

  //#region Cv
  `setDefaultCv`,
  `getCVs`,
  `uploadCv`,
  `deleteCV`,
  //#endregion

  //#region SMTP

  `updateSmtpSettings`,
  `deleteSmtpSettings`,
  //#endregion

  //#region Inbox
  `getMyEmails`,
  //#endregion

  //#region Application
  `getMyApplication`,
  //#endregion

  //#region MyQuestion
  `getMyQuestions`,
  `updateQuestion`,
  //#endregion

  `overviewStats`,
  `getDashboardUser`,
  "billing",
  "getApplications",
  "getSubscription",
  "getB2CSubscription",
  "updateMatchesInterviewStatus",
  "updateUserData",
  "updateLoopCount",
  "subscribePartnerBilling",
  "setJustRegistered",
  "updateOnboardingSteps",
  "optimisticUpdateOnboardingStep",
  "putScreeningQuestions",
  "getScreeningQuestions",
  "isExtensionInstalled"
];

export const actions = generateActionTypes(Module, actionsList);

export const mutation = {
  UpdateUserData: "updateUserData",
  SetUser: "setUser",
  SetStatus: "setStatus",
  SetLoading: "setLoading",
  SetInit: "setInit",
  SetCvs: "setCvs",
  SetUploadedCV: "setUploadedCV",
  AdjustLoopCount: "adjustLoopCount",
  SetJustRegistered: "setJustRegistered",
  OptimisticOnboardingStep: "optimisticOnboardingStep",
  SetExtensionInstalled:"setExtensionInstalled"
};

export const getters = {
  getLoading: "getLoading",
  getStatus: "getStatus",
  isUserPartner: "isUserPartner",
  loggedInUserFullName: "loggedInUserFullName",
  userLoaded: "userLoaded",
  getUser: "getUser",
  getCvs: "getCvs",
  getUploadedCV: "getUploadedCV",
  justRegistered: "justRegistered",
  isExtensionInstalled:"isExtensionInstalled"

};
