import * as repositories from "@/repositories/client";
import { clientDataStatus, mutation, errors } from "./data";
import { clientRepo } from "@/repositories/index";

export async function fetchClients({ commit }, params) {
  commit(mutation.setLoading, true);
  try {
    const page = params && params.page ? params.page : 1;
    const itemsPerPage = params && params.itemsPerPage ? params.itemsPerPage : 10;
    const response = await repositories.getClients(page, itemsPerPage);
    commit(mutation.setClients, response);
    commit(mutation.setState, clientDataStatus.successFetchClients);
    return response;
  } catch (error) {
    console.log(error);
    commit(mutation.setState, clientDataStatus.failureFetchClients);
  } finally {
    commit(mutation.setLoading, false);
  }
}

export async function createClient({ commit }, params) {
  try {
    await repositories.createClient(params.client);
  } catch (error) {
    throw error;
  }
}

export async function deleteClient({ commit }, { userId }) {
  try {
    const res = await clientRepo.deleteClient({ userId });
    return res;
  } catch (e) {
    this.$notify({
      message: this.$t("Generic.Messages.Error.remove_user"),
      type: "danger",
    });
    throw e;
  }
}

export async function fetchSubscription({ commit }) {
  commit(mutation.setLoading, true);
  try {
    const response = await repositories.fetchSubscription();
    if (response != null) {
      commit(mutation.setSubscription, response);
    }
    commit(mutation.setState, clientDataStatus.successCreateClients);
  } catch (e) {
    commit(mutation.setState, clientDataStatus.failureFetchSubscription);
  } finally {
    commit(mutation.setLoading, false);
  }
}

export async function deleteCV({}, payload) {
  try {
    return await clientRepo.deleteCV(payload);
  } catch (e) {
    throw e;
  }
}

export async function manualApplication({}, payload) {
  try {
    return await clientRepo.manualApplication(payload);
  } catch (e) {
    throw e;
  }
}
