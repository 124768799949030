export function getUser(state) {
  return state.user;
}

export function userLoaded(state) {
  return !!state.user?.email;
}

export function loggedInUserFullName(state) {
  if (!state.user) {
    return "";
  }
  const { firstName, lastName } = state.user;
  if (!firstName || !lastName) {
    return "";
  }
  return `${firstName} ${lastName}`;
}

export function getLoading(state) {
  return state.loading;
}

export function getStatus(state) {
  return state.status;
}

export function isUserPartner(state) {
  return state.user?.is_partner || false;
}

export function getCvs(state) {
  return state.cvs;
}

export function getUploadedCV(state) {
  return state.uploadedCV;
}


export function justRegistered(state) {
  return !!state?.justRegistered;
}



export function isExtensionInstalled(state) {
  return state?.isExtensionInstalled;
}
