<template>
  <div :class="dbStyle + ' block'">
    <label class="input-title header5"
      >{{ title }} <span class="required">{{ required == true ? "*" : "" }}</span></label
    >
    <div :class="`${dbStyle}_input ${inputBlockClass}`" @click="handleShowMenu">
      <!-- Input menu -->
      <input :class="inputClass" :placeholder="placeholder" v-model="inputValue" type="text" :disabled="uneditable" />
      <span class="icons">
        <ArrowDownIcon v-if="!this.showOptions && !this.uneditable" />
        <ArrowUpIcon v-if="this.showOptions && !this.uneditable" />
      </span>

      <!-- Options menu -->
      <div :class="`${dbStyle}_options options-block`" v-if="showOptions">
        <label
          :class="value == option ? optionClass + ' option-selected' : optionClass"
          :key="option"
          v-for="option in options"
          @click="handleOption(option)"
          ><span>{{ option }}</span
          ><Check v-if="value == option"
        /></label>
      </div>
    </div>
    <label class="error-message header7">{{ error }}</label>
  </div>
</template>

<script>
// @ts-nocheck

import { ArrowDownIcon, ArrowUpIcon } from "@/assets/fonts/icons/index";
import Check from "vue-material-design-icons/Check.vue";
export default {
  components: {
    ArrowDownIcon,
    ArrowUpIcon,
    Check,
  },

  props: {
    title: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    error: {
      type: String,
      default: "",
    },
    value: {
      type: String,
    },
    uneditable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Array, String],
    },
    width: {
      type: String,
    },
    dbStyle: {
      type: [Array, String],
      validator(value) {
        return ["mini"].includes(value);
      },
    },
  },

  data() {
    return {
      inputBlockClass: "input-block",
      inputClass: "header7 input-text-field",
      showOptions: false,
      optionClass: "option button2",
    };
  },

  watch: {
    error(value) {
      if (value != "") {
        this.inputBlockClass = this.inputBlockClass + " hasError";
      } else {
        this.inputBlockClass = "input-block";
      }
    },
  },

  created() {
    if (this.uneditable) {
      this.inputBlockClass = this.inputBlockClass + " uneditable-input-block";
      this.inputClass = this.inputClass + " uneditable-input";
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    handleOption(value) {
      this.$emit("input", value);
    },

    handleShowMenu() {
      if (this.uneditable == true) {
        this.showOptions = false;
      } else {
        this.showOptions = !this.showOptions;
      }
    },
  },
};
</script>

<style scoped>
.block {
  display: flex;
  flex-direction: column;
}

.input-title {
  color: #989696;
}

.required {
  color: var(--blue);
}

/* input */
.input-block {
  margin-top: 7px;
  border: 1px solid #acacaf;
  border-radius: 6px;
  display: inline-block;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16.31px;
  padding-bottom: 16.69px;
  padding-left: 18px;
  padding-right: 18px;
  color: var(--black);
  position: relative;
  box-shadow: 0.1px 0.1px 10px lightgrey;
  cursor: pointer;
}
.input-block:focus-within {
  border: 1px solid var(--blue);
  border-radius: 6px;
  opacity: 1;
  outline-width: 0;
}

.input-block::placeholder {
  color: #989696;
}

.input-text-field {
  color: transparent;
  text-shadow: 0 0 0 #35383c;
  width: -webkit-fill-available;
  cursor: pointer;
}

.input-text-field:focus {
  outline: none;
}

.input-text-field,
.input-text-field:focus-visible {
  border: 0;
  outline-width: 0;
}

/* uneditable input */
.uneditable-input-block {
  cursor: context-menu;
  background-color: #f8f8fa;
  border: 0;
}

.uneditable-input {
  cursor: context-menu;
  border: 0;
}

/* error input */
.hasError {
  border: 1px solid #d8685e;
  border-radius: 6px;
  opacity: 1;
}

.error-message {
  color: #d8685e;
  margin-top: 2px;
}

/* icons input */
.icons {
  color: #acacaf;
  cursor: pointer;
  left: auto;
  margin-bottom: -5px;
  display: inline-flex;
}

.icons span {
  margin-left: 5px;
}

.icons span:focus,
.icons span:focus-visible,
.icons span:hover {
  color: #989696 !important;
  outline-width: 0;
}

/* options */
.options-block {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 24px #696d7729;
  border-radius: 6px;
  opacity: 1;
  padding-left: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 18px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 55px;
  z-index: 10;
  width: 100%;
  left: 0;
  max-height: 220px;
  overflow-y: auto;
  box-shadow: 5px 5px 10px #888888;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.option:hover {
  cursor: pointer;
  background-color: #f8f8fa;
  border-radius: 10px;
}

.option-selected {
  color: var(--blue);
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* styles */
.mini {
  width: 30% !important;
}

.mini_input {
  padding-block: 10px !important;
}

.mini_options {
  top: 40px !important;
}
</style>

<!-- Example
  <Dropdown
      :title="'Experience'"
      :required="true"
      :placeholder="'Select your'"
      :value="'Entry'"
      @input="handleDropdown"
      :options="['Entry', 'Middle', 'Senior', 'Associate', 'Associate']"
      :uneditable="true"
      :error="'Κατι παει λαθος'"
  />
-->
