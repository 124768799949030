import appSDK from "@/client/AppSDK";
import axios from "@/core/axios";

export async function getClients(page, itemsPerPage) {
  try {
    return await axios({}).get("/partner/users", {
      params: {
        perPage: itemsPerPage,
        page: page,
      },
    });
  } catch (e) {
    throw e;
  }
}

export async function createClient(client) {
  try {
    const res = await appSDK["partner.childUser.create"](client);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deactivateClient(id) {
  try {
    await appSDK["partner.childUser.status"](id, {
      status: "inactive",
    });
  } catch (e) {
    throw e;
  }
}

export async function fetchSubscription() {
  try {
    return await appSDK["admin.subscription.get"]();
  } catch (e) {
    if (e.statusCode == 404) {
      return null;
    } else {
      throw e;
    }
  }
}

async function getQuestions(payload) {
  try {
    return axios({}).get(`/partner/user/${payload.partner_id}/questions`, {
      params: {
        isAnswered: payload.isAnswered,
        perPage: payload.items,
        page: payload.page,
        matchId: payload.matchId,
        sort: payload.sort,
        order: "desc",
        search: payload.search,
        fromDate: payload.fromDate,
        toDate: payload.toDate,
      },
    });
  } catch (e) {
    throw e;
  }
}

async function updateQuestion(payload) {
  try {
    return axios({}).post(`/partner/user/${payload.partner_id}/questions`, {
      answers: payload.answers,
    });
  } catch (e) {
    throw e;
  }
}

async function deleteClient({ userId }) {
  try {
    return axios({}).delete(`/partner/user/${userId}`);
  } catch (e) {
    throw e;
  }
}

async function deleteCV(payload) {
  try {
    return axios({}).delete(`/partner/user/${payload.userId}/cvs/${payload.cvId}`);
  } catch (e) {
    throw e;
  }
}

async function manualApplication(payload) {
  try {
    return axios({}).post(`/partner/user/${payload.userId}/application`, {
      title: payload.title,
      company: payload.company,
      location: payload.location,
      url: payload.url,
      date: payload.date,
      interviewed: payload.interviewed,
    });
  } catch (e) {
    throw e;
  }
}

export const clientRepo = {
  getQuestions,
  updateQuestion,
  deleteClient,
  deleteCV,
  manualApplication,
};
