<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Next">
      <path
        id="Vector"
        d="M14.0001 26.3334C17.271 26.3334 20.4081 25.034 22.721 22.721C25.034 20.4081 26.3334 17.2711 26.3334 14.0001C26.3334 10.7291 25.034 7.59205 22.721 5.27911C20.4081 2.96617 17.271 1.66677 14.0001 1.66677C10.7291 1.66677 7.59203 2.96617 5.27909 5.27911C2.96615 7.59205 1.66675 10.7291 1.66675 14.0001C1.66675 17.2711 2.96615 20.4081 5.27909 22.721C7.59203 25.034 10.7291 26.3334 14.0001 26.3334Z"
        :stroke="getIconColor"
        stroke-opacity="0.6"
        stroke-width="1.99999"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M12.0774 18.6278L16.7254 13.9998L12.0774 9.37183"
        :stroke="getIconColor"
        stroke-opacity="0.6"
        stroke-width="1.99999"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#192334",
    },
  },

  computed: {
    getIconColor() {
      return this.color;
    },
  },
};
</script>
