import appSDK from "@/client/AppSDK";

export async function getPrices() {
  try {
    return await appSDK["payments.getPrices"]();
  } catch (e) {
    throw e;
  }
}

export async function cancelSubscription(payload) {
  try {
    const res = await appSDK["payments.cancelSubscription"](payload.reason, payload.plan);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function createSubscription(payload) {
  try {
    return await appSDK["payments.createSubscription"](payload);
  } catch (e) {
    throw e;
  }
}

export async function setPromoCode(payload) {
  try {
    return await appSDK["payments.getDiscount"](payload.promoCode);
  } catch (e) {
    throw e;
  }
}
