<template>
  <v-app-bar class="app-header" flat>
    <v-app-bar-nav-icon v-if="pageName != null" @click.stop="toggleSidebar" />
    <v-toolbar-title class="page_header" v-if="pageName != null">{{ pageName }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <slot></slot>
    <v-btn v-if="showNewLoopbtn" exact rounded to="/loops/create" variant="elevated" class="sidebar-item newloop-btn" color="primary">
      {{ $t("page.loopCreate") }}
    </v-btn>
    <v-menu :persistent="false" close-on-content-click>
      <template v-slot:activator="{ props }">
        <v-btn variant="text" tile v-bind="props">
          <app-icon size="medium" name="xdProfileAppBar" />
          <span class="pl-2">{{ loggedInUserFullName }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item to="/profile/info">
          <v-list-item-title>{{ this.$t("page.account_settings") }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/profile/pricing" v-if="showPricingPlan">
          <v-list-item-title>{{ this.$t("page.profilePricing") }}</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout">
          <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import EventBus from "@/plugins/eventsBus";
import { Role } from "@/helpers/enums/eRole";

const user = constant.user;

export default {
  name: "AppHeader",
  components: {
    LocaleSwitcher,
  },

  watch: {
    status(value) {
      switch (value) {
        case user.status.SuccessSignOut:
          this.$router.push("/login");
          break;
        case user.status.FailureSignOut:
          this.$notify({
            message: this.$t("errors.unexpected"),
            type: "danger",
          });
          break;
      }
    },
  },

  computed: {
    ...mapGetters("user", {
      user: user.getters.getUser,
      getStatus: user.getters.getStatus,
      loggedInUserFullName: user.getters.loggedInUserFullName,
      isUserPartner: user.getters.isUserPartner,
    }
    ),
    isB2C() {
      return this.$store.getters.isB2C;
    },
    showNewLoopbtn() {
      return (this.user?.role != Role.ADMIN && this.user.role != Role.MANAGER) || this.isB2C;
    },
    pageName() {
      const pageName = this.camelCase(this.$route.name);
      if (pageName) {
        return this.$t(`page.${pageName}`);
      } else {
        return null;
      }
    },

    showPricingPlan() {
      return !this.isUserPartner && this.$store.getters.configs?.showPricingPlans;
    },

    status() {
      return this.getStatus;
    },
  },
  methods: {
    toggleSidebar() {
      EventBus.emit("toggle-sidebar");
    },
    camelCase(text) {
      if (text) {
        const words = text.split("-");
        return words.reduce((output, word, index) => {
          if (!index) return word;
          output += word.charAt(0).toUpperCase() + word.slice(1);
          return output;
        }, "");
      }
    },
    logout() {
      this.$store.dispatch(user.actions.SignOut);
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.page_header {
  display: inline;
}

@media screen and (max-width: 600px) {
  .app-header {
    width: 100vw !important;
  }

  .page_header {
    display: none;
  }

  .newloop-btn {
    width: max-content;
  }
}
</style>
