import router from "../router";

// migration of old query-mode routes
// so that it does not throw an error and redirects to the correct page
// users may have the old URL in a bookmark

router.beforeEach((to, from, next) => {
  if (to.hash === "#/newsearch" || to.hash === "#/newloop") {
    return next("/loops/create");
  } else if (to.hash.startsWith("#/") || to.path === "/setupprofile") {
    return next("/");
  }

  return next();
});
