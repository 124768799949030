<template>
  <div class="icon-rounded icon_rounded_submit" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <SubmitIcon :color="iconColor" />
  </div>
</template>

<script>
import { SubmitIcon } from "@/assets/fonts/icons";

export default {
  components: {
    SubmitIcon,
  },

  data() {
    return {
      iconColor: "#192334",
    };
  },

  methods: {
    handleMouseEnter() {
      this.iconColor = "#0024cb";
    },

    handleMouseLeave() {
      this.iconColor = "#192334";
    },
  },
};
</script>
