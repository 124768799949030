<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      :stroke="selected ? '#175c9f' : '#000000'"
      :stroke-width="selected ? '120' : '70'"
    >
      <path
        d="M1715 5099 c-130 -45 -219 -148 -244 -284 -6 -32 -11 -110 -11 -172
 l0 -113 -362 0 c-346 0 -365 -1 -396 -20 -36 -22 -62 -72 -62 -117 0 -51 28
 -161 55 -218 l27 -56 -24 -37 c-48 -75 -51 -90 -58 -282 -5 -162 -8 -191 -28
 -230 -19 -40 -22 -61 -22 -195 0 -174 10 -208 80 -267 42 -36 105 -58 169 -58
 27 0 29 -4 45 -72 37 -154 151 -334 260 -409 l36 -24 0 -103 c0 -74 -4 -104
 -12 -106 -7 -2 -34 -8 -60 -15 -26 -6 -89 -31 -140 -56 -51 -24 -138 -62 -192
 -85 -113 -45 -254 -122 -297 -162 -36 -32 -38 -70 -4 -103 33 -34 65 -31 132
 12 57 36 233 123 249 123 5 0 63 -66 128 -146 133 -165 174 -194 273 -194 51
 0 51 0 102 -64 l51 -64 -110 -684 c-60 -375 -112 -698 -115 -715 l-6 -33 -234
 0 -235 0 0 309 c0 317 -5 362 -40 381 -30 16 -68 12 -90 -10 -19 -19 -20 -33
 -20 -350 l0 -330 -132 0 c-148 0 -196 14 -236 65 -42 53 -44 78 -40 580 4 480
 4 480 30 576 26 97 72 198 127 279 39 57 41 101 5 129 -38 30 -62 26 -103 -17
 -51 -55 -99 -139 -138 -243 -67 -179 -68 -188 -68 -759 l0 -515 28 -56 c32
 -66 99 -132 165 -162 l47 -22 1350 0 1350 0 56 26 c115 54 180 153 187 286 l5
 83 806 0 c896 0 867 -2 968 66 28 20 67 59 87 87 70 104 66 21 66 1437 0 1277
 0 1277 -21 1304 -26 33 -79 36 -109 6 -20 -20 -20 -33 -20 -1292 0 -858 -4
 -1285 -11 -1311 -13 -49 -63 -105 -114 -128 -38 -17 -88 -19 -847 -19 l-808 0
 0 339 c0 258 -4 359 -15 427 -69 398 -337 698 -782 875 -43 17 -124 53 -181
 80 -56 27 -121 52 -145 55 -23 4 -45 12 -49 18 -4 6 -8 50 -8 99 l0 87 93 93
 c53 51 108 117 126 149 35 62 75 172 85 235 l7 40 60 6 c120 11 197 75 220
 185 6 33 10 105 7 172 -3 101 -8 124 -31 172 -26 54 -27 59 -27 246 0 221 -7
 267 -62 381 -55 115 -144 204 -259 259 -112 54 -165 62 -411 62 l-208 0 0 110
 c0 144 12 197 58 250 73 86 -29 80 1249 80 l1132 0 3 -327 c3 -313 4 -330 25
 -376 33 -70 113 -149 186 -183 l62 -29 323 -3 322 -3 0 -235 0 -235 25 -24
 c31 -32 69 -32 101 0 l25 25 -3 268 -3 267 -28 51 c-20 36 -160 182 -465 486
 -392 390 -442 437 -486 452 -45 14 -163 16 -1205 16 -1139 -1 -1157 -1 -1216
 -21z m2908 -897 c-263 -3 -293 1 -350 51 -68 60 -73 81 -73 362 l0 250 330
 -330 330 -330 -237 -3z m-2539 163 c73 -19 134 -55 190 -111 99 -98 126 -183
 126 -392 l0 -143 -36 3 c-35 3 -37 5 -50 55 -24 92 -84 166 -177 215 -42 23
 -47 23 -542 28 -441 5 -505 8 -545 23 -134 52 -230 167 -254 305 l-5 32 617 0
 c519 0 627 -2 676 -15z m-1210 -396 l44 -31 -19 -56 c-11 -31 -22 -80 -26
 -109 l-6 -53 -38 0 -39 0 0 105 c0 70 5 117 14 140 8 19 17 35 20 35 3 0 25
 -14 50 -31z m702 -99 l491 -5 36 -28 c73 -55 72 -51 72 -442 0 -334 -1 -353
 -22 -420 -61 -192 -218 -342 -418 -401 -286 -83 -623 119 -700 421 -12 47 -15
 129 -15 410 0 211 4 366 11 390 18 69 31 95 43 87 6 -4 232 -9 502 -12z m-708
 -482 l-3 -183 -42 1 c-76 1 -83 16 -83 166 0 71 5 138 10 148 14 26 58 49 93
 50 l27 0 -2 -182z m1541 166 c13 -9 29 -32 37 -50 17 -40 19 -207 4 -251 -12
 -33 -45 -53 -90 -53 l-30 0 0 185 0 185 28 0 c15 0 38 -7 51 -16z m-855 -1151
 c74 -6 206 14 272 42 l32 13 5 -72 c4 -39 9 -83 13 -97 5 -24 -5 -35 -137
 -137 l-143 -111 -80 63 c-45 34 -109 85 -144 111 -52 40 -62 53 -56 70 3 11 9
 54 11 96 l5 76 75 -24 c41 -13 108 -26 147 -30z m-244 -329 c74 -58 139 -109
 143 -113 5 -5 -27 -29 -71 -55 -87 -51 -140 -59 -178 -28 -24 20 -194 227
 -194 236 0 10 122 64 145 65 11 0 81 -47 155 -105z m809 75 l70 -31 -99 -123
 c-54 -67 -106 -125 -115 -129 -42 -16 -86 -5 -164 41 -44 26 -79 48 -78 50 35
 34 287 222 299 222 10 1 49 -13 87 -30z m330 -149 c301 -147 484 -351 563
 -626 21 -76 22 -97 26 -569 2 -269 1 -505 -3 -523 -7 -40 -59 -101 -102 -118
 -22 -9 -76 -14 -162 -14 l-130 0 -3 331 -3 331 -24 19 c-31 25 -69 24 -98 -3
 l-23 -21 0 -329 0 -328 -240 0 c-157 0 -240 4 -240 10 0 11 -218 1375 -224
 1402 -2 9 20 44 50 81 52 63 54 64 104 66 100 2 139 31 285 215 55 69 105 125
 111 126 6 0 57 -22 113 -50z m-737 -209 c16 -9 28 -19 28 -22 0 -3 -17 -27
 -37 -53 l-38 -46 -66 0 -67 0 -37 45 c-20 25 -35 50 -33 55 2 6 32 28 68 49
 l65 40 45 -27 c25 -14 57 -33 72 -41z m-68 -298 c6 -28 216 -1330 216 -1339 0
 -2 -119 -4 -265 -4 l-265 0 5 22 c5 26 215 1331 215 1341 0 4 20 7 44 7 40 0
 45 -3 50 -27z"
      />
      <path
        d="M2919 3717 c-51 -34 -58 -67 -58 -287 0 -220 7 -253 58 -287 l34 -23
 801 0 c621 0 807 3 826 13 13 7 35 28 47 46 22 33 23 42 23 251 0 209 -1 218
 -23 251 -12 18 -34 39 -47 46 -19 10 -205 13 -826 13 l-801 0 -34 -23z m1581
 -287 l0 -160 -745 0 -745 0 0 160 0 160 745 0 745 0 0 -160z"
      />
      <path
        d="M2885 2890 c-25 -28 -23 -76 4 -103 l22 -22 845 0 846 0 24 28 c29
 34 30 56 3 91 l-20 26 -853 0 c-842 0 -853 0 -871 -20z"
      />
      <path
        d="M2899 2567 c-26 -20 -40 -55 -33 -82 14 -57 -26 -55 892 -55 l843 0
 24 25 c14 13 25 36 25 50 0 14 -11 37 -25 50 l-24 25 -843 0 c-647 -1 -847 -3
 -859 -13z"
      />
      <path
        d="M2887 2223 c-8 -10 -19 -30 -22 -45 -6 -22 -1 -33 24 -58 l30 -31
 436 3 437 3 19 24 c25 30 24 72 -1 99 -21 22 -21 22 -464 22 -409 0 -444 -1
 -459 -17z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
