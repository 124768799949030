export function setClients(state, payload) {
  state.clients = payload.users;
  state.totalClients = payload.total;
}

export function setState(state, payload) {
  state.status = payload;
}

export function setLoading(state, payload) {
  state.loading = payload;
}

export function setError(state, payload) {
  state.error = payload;
}

export function setEmailOfUserThatWasDeleted(state, payload) {
  state.emailOfUserThatWasDeleted = payload;
}

export function setSubscription(state, payload) {
  state.subscription = payload;
}
