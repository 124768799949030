<template>
  <div class="block">
    <label class="switch">
      <input type="checkbox" :disabled="disable" :checked="value" v-model="inputValue" />
      <span :class="sliderClass"></span>
    </label>
    <span class="header5">{{ this.title }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sliderClass: "slider",
    };
  },

  watch: {
    disable(value) {
      if (value != "") {
        this.sliderClass = this.sliderClass + " uneditable-slider";
      } else {
        this.sliderClass = this.sliderClass.replace(" uneditable-slider", "");
      }
    },
  },

  created() {
    if (this.disable) {
      this.sliderClass = this.sliderClass + " uneditable-slider";
    } else {
      this.sliderClass = this.sliderClass.replace(" uneditable-slider", "");
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 10px;
  margin-right: 15px;
}

/* Switch Toggle*/
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5ea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -6px;
  bottom: -5px;
  background-color: #acacac;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

input:checked + .slider {
  background-color: #e5e5ea;
}
input:checked + .slider:before {
  background-color: var(--blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px red;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* error input */
.hasError {
  border: 1px solid #d8685e;
  border-radius: 6px;
  opacity: 1;
}

.error-message {
  color: #d8685e;
  margin-top: 2px;
  margin-bottom: 5px;
}

.uneditable-input-block {
  background-color: #f8f8fa;
}
.uneditable-slider {
  cursor: not-allowed !important;
}
</style>
