<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffc107" stroke="none">
      <path
        d="M2462 4869 c-217 -28 -418 -151 -538 -329 -17 -25 -440 -754 -940
-1620 -694 -1202 -915 -1594 -936 -1655 -70 -207 -55 -417 43 -606 98 -189
263 -324 479 -391 l75 -23 1865 -3 c1346 -2 1887 0 1944 8 412 59 705 436 657
843 -11 92 -42 201 -76 268 -54 104 -1815 3144 -1850 3194 -54 76 -154 169
-229 214 -77 46 -197 87 -290 100 -86 12 -112 12 -204 0z m231 -319 c85 -26
179 -90 227 -154 53 -70 1828 -3147 1858 -3220 101 -249 -57 -550 -321 -611
-58 -13 -285 -15 -1929 -13 l-1863 3 -58 23 c-117 48 -222 152 -269 270 -19
47 -23 75 -22 167 0 95 3 118 24 166 14 30 432 762 930 1625 989 1714 946
1646 1078 1713 34 17 79 35 100 40 68 16 176 12 245 -9z"
      />
      <path
        d="M2454 3766 c-59 -28 -119 -94 -137 -150 -9 -31 -12 -222 -12 -781 l0
-740 23 -50 c44 -95 114 -144 217 -153 77 -6 149 23 201 80 73 82 69 28 69
863 0 827 2 795 -64 868 -76 85 -196 110 -297 63z"
      />
      <path
        d="M2497 1566 c-115 -31 -189 -127 -188 -244 1 -275 373 -353 484 -102
63 141 -26 317 -176 350 -58 12 -59 12 -120 -4z"
      />
    </g>
  </svg>
</template>
