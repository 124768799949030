export function getPlans(state) {
  return state.plans;
}

export function getStatus(state) {
  return state.status;
}

export function getPromoCode(state) {
  return state.promoCode;
}

export function getPromoCodeError(state) {
  return state.promoCodeError;
}
