export function hasEqualValues(array1, array2) {
  if (array1 == null && array2 == null) {
    return true;
  } else if (array1 == null || array2 == null) {
    return false;
  } else {
    if (array1.length != array2.length) return false;
    for (let value of array1) {
      if (!array2.includes(value)) {
        return false;
      }
      return true;
    }
  }
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function generateActionTypes(module, actions) {
  const actionTypes = {};
  for (const action of actions) {
    actionTypes[capitalizeFirstLetter(action)] = `${module}/${action}`;
  }
  return actionTypes;
}
