<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      :stroke="selected ? '#175c9f' : '#000000'"
      :stroke-width="selected ? '80' : '20'"
    >
      <path
        d="M1961 4944 c-177 -47 -328 -208 -366 -391 -8 -38 -15 -124 -15 -195
l0 -128 -378 0 c-335 0 -385 -2 -443 -18 -157 -44 -283 -159 -347 -319 l-27
-68 -3 -1585 c-2 -1120 0 -1604 8 -1650 14 -80 66 -190 120 -252 55 -64 148
-124 234 -151 l71 -22 1130 0 1130 0 71 22 c130 41 238 131 302 251 46 86 62
166 62 319 l0 131 388 4 c365 4 391 6 453 26 171 56 303 199 344 373 22 94 23
2535 1 2577 -13 24 -807 820 -1012 1015 l-82 77 -793 -1 c-661 0 -803 -3 -848
-15z m1499 -467 c0 -159 5 -309 11 -343 27 -147 139 -298 270 -363 108 -53
171 -61 482 -61 l277 0 -2 -1197 -3 -1198 -23 -43 c-31 -58 -88 -114 -148
-143 -49 -24 -50 -24 -431 -27 l-383 -3 0 1013 0 1013 -553 552 -552 553 -308
0 -309 0 4 153 c3 141 5 155 30 203 42 80 121 143 205 163 21 5 352 9 736 10
l697 1 0 -283z m683 -555 c-238 -3 -282 5 -360 66 -27 22 -61 62 -78 93 l-30
54 -3 235 -3 235 341 -340 340 -340 -207 -3z m-1883 -185 c0 -323 6 -371 61
-479 65 -129 217 -241 363 -267 33 -6 183 -11 338 -11 l278 0 -2 -1197 -3
-1198 -30 -54 c-17 -31 -51 -71 -78 -93 -92 -72 -26 -69 -1263 -66 l-1109 3
-48 23 c-68 34 -132 102 -156 167 -21 55 -21 63 -21 1638 l0 1582 28 60 c45
94 122 154 226 175 23 4 350 8 729 8 l687 1 0 -292z m676 -545 c-195 -2 -219
-1 -268 18 -71 26 -126 75 -163 141 l-30 54 -3 235 -3 235 341 -340 340 -340
-214 -3z"
      />
      <path
        d="M3714 2542 c-53 -42 -59 -109 -15 -153 l29 -29 197 0 c115 0 204 4
216 10 23 13 49 61 49 90 0 31 -29 76 -57 89 -15 7 -97 11 -211 11 -162 0
-190 -3 -208 -18z"
      />
      <path
        d="M3721 1816 c-51 -28 -66 -104 -31 -149 30 -38 77 -47 240 -47 173 0
214 9 240 53 30 48 26 83 -14 123 l-34 34 -188 0 c-138 -1 -196 -4 -213 -14z"
      />
      <path
        d="M942 2535 c-51 -43 -51 -107 -1 -154 23 -21 28 -21 587 -21 l564 0
29 29 c41 41 41 101 0 142 l-29 29 -560 0 -560 0 -30 -25z"
      />
      <path
        d="M942 1805 c-16 -14 -33 -37 -36 -51 -10 -37 10 -89 42 -111 26 -17
75 -18 975 -21 653 -2 960 1 988 8 81 22 102 116 39 173 l-30 27 -974 0 -974
0 -30 -25z"
      />
      <path
        d="M942 1075 c-40 -34 -49 -75 -25 -123 13 -26 28 -41 53 -49 47 -16
1886 -18 1943 -2 79 22 99 116 37 172 l-30 27 -974 0 -974 0 -30 -25z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
