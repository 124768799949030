<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      :stroke="selected ? '#175c9f' : '#000000'"
      :stroke-width="selected ? '120' : '70'"
    >
      <path
        d="M361 5104 c-154 -41 -267 -159 -301 -314 -8 -37 -11 -137 -8 -310 l3
-255 32 -67 c41 -87 109 -160 187 -198 l61 -31 5 -1167 5 -1167 26 -66 c66
-171 211 -286 390 -309 42 -5 420 -10 877 -10 l802 0 0 -217 -1 -218 -366
-280 c-201 -154 -374 -293 -384 -310 -56 -89 32 -206 132 -176 23 7 173 116
381 276 188 146 348 264 355 262 7 -1 159 -114 339 -252 372 -284 388 -295
430 -295 75 0 137 85 114 156 -15 44 -34 60 -423 354 l-337 255 0 222 0 222
853 4 c753 3 857 5 898 19 154 54 265 164 310 309 18 58 19 110 19 1220 l0
1159 64 29 c109 49 187 135 225 249 19 56 21 85 21 309 0 178 -4 263 -14 299
-40 152 -149 259 -303 299 -85 22 -4309 21 -4392 -1z m4384 -259 c75 -43 80
-64 80 -335 0 -251 -3 -268 -53 -313 -53 -48 38 -46 -2212 -46 -2250 0 -2159
-2 -2212 46 -50 45 -53 62 -53 313 0 270 5 291 79 335 l41 25 2144 0 2144 0
42 -25z m-225 -2069 c0 -772 -3 -1144 -11 -1163 -21 -56 -61 -102 -112 -128
l-51 -25 -1790 0 c-1716 0 -1793 1 -1831 19 -51 23 -93 65 -116 116 -18 38
-19 96 -19 1178 l0 1137 1965 0 1965 0 0 -1134z"
      />
      <path
        d="M1129 3425 c-56 -31 -78 -117 -43 -171 37 -55 25 -54 654 -54 637 0
623 -1 655 60 31 59 11 130 -45 164 -25 14 -89 16 -612 16 -483 -1 -587 -3
-609 -15z"
      />
      <path
        d="M1134 2814 c-66 -32 -86 -127 -38 -183 14 -17 42 -35 62 -41 51 -14
2753 -14 2804 0 77 22 112 111 71 181 -37 63 55 59 -1475 59 -1220 0 -1397 -2
-1424 -16z"
      />
      <path
        d="M1144 2206 c-98 -43 -100 -171 -4 -221 44 -23 2796 -23 2840 0 97 50
94 179 -6 221 -49 20 -2783 20 -2830 0z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
