<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="5.204" height="8.824" viewBox="0 0 5.204 8.824">
    <path class="a" d="M0,0,3.9,3.63,7.8,0" transform="translate(0.549 8.314) rotate(-90)" />
  </svg>
</template>

<style scoped>
.a {
  fill: none;
  stroke: #081929;
  stroke-width: 2px;
}
</style>
