<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9.44" height="5.781" viewBox="0 0 9.44 5.781">
    <path class="a" d="M1227.484,125.049l4.189,4.19,4.189-4.19" transform="translate(-1226.953 -124.519)" />
  </svg>
</template>

<style scoped>
.a {
  fill: none;
  stroke: #081929;
  stroke-width: 1.5px;
}
</style>
