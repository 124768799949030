<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card class="pa-6">
      <div class="text-h6">{{ $t(`plans.CancelReasons.title`) }}</div>
      <v-radio-group v-model="selectedReason">
        <v-radio v-for="(reason, index) in reasonsList" :key="index" :label="cancelReason(reason)" :value="reason"></v-radio>
        <span>
          <v-radio :label="$t(`plans.CancelReasons.other`)" value="other"> </v-radio>
          <v-textarea
            v-if="selectedReason === 'other'"
            name="input-7-1"
            label="Unsubscription reason"
            v-model="otherReasonValue"
          ></v-textarea>
        </span>
      </v-radio-group>

      <p>
        {{ this.$t("plans.Common.want_cancel_subscription") }}
        <template v-if="supportEmail">
          {{ this.$t("Generic.Messages.Info.send_email") }}
          <i><base-mailto :email="supportEmail" /></i>.
        </template>
      </p>
      <v-btn variant="text" :loading="cancelSubscriptionIsLoading" @click="cancelSubscription">
        {{ $t("yes") }}
      </v-btn>
      <v-btn color="primary" @click="closeDialog()">
        {{ $t("no") }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { constant } from "@/store/constant";

const user = constant.user;
const plans = constant.plans;

export default {
  data() {
    return {
      dialog: false,
      cancelSubscriptionIsLoading: false,
      selectedReason: "",
      otherReasonValue: "",
      reasonsList: [
        "expensivePlans",
        "noMatches",
        "noApplications",
        "noControl",
        "matchesIrrelevant",
        "foundAJobViaBrand",
        "foundAJob",
        "dontNeedAnymore",
      ],
    };
  },

  computed: {
    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },

   isB2C() {
      return this.$store.getters.isB2C;
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    cancelReason(reason) {
      return this.$t(`plans.CancelReasons.${reason}`, { brand: this.$store.getters.brand });
    },

    async cancelSubscription() {
      if (!this.selectedReason) {
        this.$notify({
          message: this.$t("plans.Common.select_reason"),
          type: "danger",
        });

        return;
      }

      if (this.selectedReason === "other" && this.otherReasonValue.length < 1) {
        this.$notify({
          message: this.$t("plans.Common.explain_reason"),
          type: "danger",
        });

        return;
      }

      this.cancelSubscriptionIsLoading = true;
      const reason =
        this.selectedReason === "other" ? this.otherReasonValue : this.$t(`plans.CancelReasons.${this.selectedReason}`, "en");

      try {
        const payload = {
          reason,
        };
        if (this.isB2C) {
          payload.plan = 1;
        }
        await this.$store.dispatch(plans.actions.CancelSubscription, payload);
        await this.$store.dispatch(user.actions.RefreshUserData);
        this.$notify({
          message: this.$t("plans.Common.success_cancel_subscription"),
          type: "success",
        });
        this.$emit('subscriptionCancelled');
      } catch (e) {
        console.log(e);
        if (e?.responseContent?.error === "no-subscriptions-exist") {
          this.$notify({
            message: this.$t("plans.Common.failure_cancel_no_plan", { email: this.supportEmail }),
            type: "danger",
          });
        } else {
          this.$notify({
            message: this.$t("Generic.Messages.Error.common"),
            type: "danger",
          });
        }
      } finally {
        this.cancelSubscriptionIsLoading = false;
        this.dialog = false;
      }
    },
  },
};
</script>
