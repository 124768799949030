<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M990 5109 c-339 -57 -635 -301 -744 -615 -59 -168 -56 -69 -56 -1934
0 -1523 2 -1719 16 -1788 46 -223 154 -406 324 -549 79 -67 232 -151 320 -178
145 -43 182 -45 935 -45 666 0 723 1 754 18 97 49 106 181 18 246 -28 21 -40
21 -775 26 -830 7 -794 4 -939 76 -159 80 -279 222 -336 399 l-22 70 0 1725 0
1725 22 70 c57 176 181 324 338 400 145 71 108 68 945 75 706 5 758 6 783 23
94 61 86 200 -13 249 -32 17 -89 18 -775 17 -407 -1 -765 -5 -795 -10z"
      />
      <path
        d="M3790 3622 c-82 -41 -106 -144 -51 -218 16 -21 176 -185 355 -364
l326 -325 -1502 -5 c-1457 -5 -1504 -6 -1535 -24 -98 -60 -94 -201 7 -253 l44
-23 1501 0 1500 0 -359 -359 -359 -359 -4 -54 c-3 -41 1 -60 16 -86 40 -62
110 -87 178 -62 32 12 986 960 1009 1002 24 46 18 110 -15 154 -16 21 -238
247 -492 501 -523 521 -525 523 -619 475z"
      />
    </g>
  </svg>
</template>
