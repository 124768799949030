import router from "../router";
import store from "@/store";

function userIsLoggedInThisSession() {
  const user = store.getters["user/getUser"];
  if (user && user.email && user.role) {
    return true;
  } else {
    return false;
  }
}

async function userIsLoggedIn() {
  // TODO check the error message "user is not logged in", log other errors, check the cookies directly
  if (userIsLoggedInThisSession()) {
    return true;
  }

  try {
    await store.dispatch("user/auth");
    return true;
  } catch (e) {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.meta?.public) {
    if (to.meta?.redirectToAppIfAuthenticated && (await userIsLoggedIn())) {
      return next(to.query.redirect || "/");
    }
    return next();
  }

  if (await userIsLoggedIn()) return next();

  // set user data
  return store
    .dispatch("user/auth")
    .then(next)
    .catch(e => {
      const client = store.getters?.configs?.brand;
      next(`/login?redirect=${to.fullPath}`);
    });
});
