<template>
  <div class="nx-body">
    <label class="header5-bold" @click="handleClick">
      {{ this.$t("loop.buttons.next") }}
    </label>
    <div class="icon-rounded" @click="handleClick">
      <ArrowRightIcon />
    </div>
  </div>
</template>

<script>
import { ArrowRightIcon } from "@/assets/fonts/icons/index.js";
export default {
  components: {
    ArrowRightIcon,
  },

  methods: {
    handleClick() {
      this.$emit("next");
    },
  },
};
</script>

<style scoped>
@import "./next.css";
</style>
