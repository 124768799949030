export function getClients(state) {
  return state.clients;
}

export function getTotalClients(state) {
  return state.totalClients;
}
export function getLoading(state) {
  return state.loading;
}

export function getStatus(state) {
  return state.status;
}

export function getError(state) {
  return state.error;
}

export function getDeleteEmailUser(state) {
  return state.emailOfUserThatWasDeleted;
}

export function getSubscription(state) {
  return state.subscription;
}
