<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="14px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2116 4460 c-45 -23 -73 -46 -89 -75 -6 -11 -48 -234 -92 -495 l-80
  -475 -540 -5 c-485 -4 -543 -7 -571 -22 -144 -79 -137 -312 11 -379 37 -17 76
  -19 528 -19 268 0 487 -2 487 -4 0 -7 -140 -809 -145 -833 l-5 -23 -413 0
  c-378 0 -415 -2 -452 -19 -154 -69 -154 -313 0 -382 36 -17 73 -19 408 -19
  350 0 367 -1 367 -18 0 -11 -34 -203 -76 -428 -78 -426 -80 -444 -44 -514 38
  -73 103 -110 198 -110 92 0 173 37 204 93 7 12 49 236 93 497 l80 475 413 3
  c395 2 412 1 412 -16 0 -10 -34 -203 -76 -428 -78 -426 -80 -444 -44 -514 38
  -73 103 -110 198 -110 92 0 174 37 204 93 6 12 52 236 102 497 l91 475 530 5
  c476 4 533 7 561 22 144 79 137 312 -11 379 -37 17 -76 19 -527 19 -269 0
  -488 2 -488 4 0 7 140 809 145 834 l5 22 413 0 c378 0 415 2 452 19 154 69
  154 313 0 382 -36 17 -73 19 -407 19 -350 0 -368 1 -368 18 0 10 32 189 71
  398 39 208 71 406 72 439 3 132 -85 215 -226 215 -90 0 -167 -37 -199 -93 -7
  -12 -49 -236 -93 -497 l-80 -475 -412 -3 c-395 -2 -413 -1 -413 16 0 10 32
  189 71 398 39 208 71 406 72 439 2 93 -44 165 -125 200 -53 22 -163 19 -212
  -5z m934 -1474 c0 -7 -140 -809 -145 -833 l-5 -23 -415 0 c-228 0 -415 2 -415
  4 0 7 140 809 145 834 l5 22 415 0 c228 0 415 -2 415 -4z"
      />
    </g>
  </svg>
</template>
