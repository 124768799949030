<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="34px" height="34px" viewBox="0 0 29.37 24.397">
    <path class="a" d="M667.527,299.314l7.745,7.745,16.03-16.495" transform="translate(-696.192 -202.396) rotate(-7)" />
  </svg>
</template>

<style scoped>
.a {
  fill: none;
  stroke: #19a59f;
  stroke-width: 4.5px;
}
</style>
