export function setPlans(state, payload) {
  state.plans = payload;
}

export function setState(state, payload) {
  state.status = payload;
}

export function setLoading(state, payload) {
  state.loading = payload;
}

export function setError(state, payload) {
  state.error = payload;
}

export function setPromoCode(state, payload) {
  state.promoCode = payload;
}

export function setPromoCodeError(state, payload) {
  state.promoCodeError = payload;
}
