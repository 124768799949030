<template>
  <div :class="getTemplate">
    <AlertWarningIcon v-if="this.$attrs.type === 'warning'" />
    <AlertErrorIcon v-else-if="this.$attrs.type === 'error'" />
    <AlertSuccessIcon v-else-if="this.$attrs.type === 'success'" />

    <label :class="getLabelTemplate">
      {{ this.$attrs.text }}
      <slot />
    </label>
  </div>
</template>

<script src="./alerts"></script>

<style scoped>
@import "./alerts.css";
</style>
