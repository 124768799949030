import AppIcon from "./AppIcon.vue";
import AppLoader from "./AppLoader.vue";
import AppNotFound from "./AppNotFound.vue";
import AppPagination from "./AppPagination.vue";
import AppTextLoader from "./AppTextLoader.vue";
import AppHeader from "./layouts/AppHeader.vue";
import Card from "./Card.vue";
import BaseMailto from "./BaseMailto.vue";

export default [AppHeader, AppIcon, AppLoader, AppNotFound, AppPagination, AppTextLoader, Card, BaseMailto];
