<template>
  <div class="checkbox_body">
    <input class="checkbox" type="checkbox" name="checkbox" v-model="inputValue" />
    <label class="header5" for="checkbox">{{ title }}</label>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },

    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputValue: {
      get() {
        // @ts-ignore
        return this.value;
      },
      set(val) {
        // @ts-ignore
        this.$emit("click", val);
      },
    },
  },
};
</script>

<style scoped>
.checkbox_body {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox {
  margin-right: 5px;
  border: 1px solid #e4ebe4;
  outline-width: 0;
  outline: none;
}

.checkbox:hover {
  border: 1px solid #c7d6c7;
  cursor: pointer;
}
</style>
