import 'vuetify/styles';
import { createVuetify } from 'vuetify'
import store from "@/store";
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { md } from 'vuetify/iconsets/md'

export default createVuetify({
  theme: {
    themes: {
      light: store.getters.configs.theme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      md,
    },
  },
});
