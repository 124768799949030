<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M877 5035 c-50 -17 -85 -50 -108 -100 -15 -33 -19 -65 -19 -177 l0
-137 -222 -3 -223 -3 -67 -32 c-76 -36 -155 -108 -187 -171 -53 -105 -51 -4
-51 -2067 0 -1672 2 -1918 15 -1969 40 -153 168 -271 321 -296 88 -15 4397
-13 4466 1 121 25 234 115 286 227 48 102 44 141 -131 1277 -86 556 -213 1386
-282 1845 -69 459 -131 855 -136 880 -15 66 -66 155 -116 201 -24 22 -74 55
-111 72 l-67 32 -292 3 -293 3 0 137 c0 154 -12 197 -69 245 -96 81 -266 54
-329 -51 -25 -44 -27 -55 -30 -189 l-4 -143 -404 0 -404 0 0 138 c0 123 -2
143 -22 182 -14 26 -41 57 -66 75 -39 27 -51 30 -120 30 -94 0 -143 -22 -187
-84 -30 -42 -30 -45 -33 -192 l-4 -149 -403 0 -403 0 -4 148 c-3 143 -4 150
-31 191 -52 78 -171 112 -270 76z m135 -152 c17 -15 18 -40 18 -364 0 -303 -2
-350 -16 -363 -18 -18 -66 -21 -95 -6 -18 10 -19 25 -19 365 0 308 2 356 16
369 20 21 73 20 96 -1z m1242 1 c14 -13 16 -60 16 -364 0 -387 1 -380 -65
-380 -66 0 -65 -7 -65 380 0 387 -1 380 65 380 18 0 41 -7 49 -16z m1237 6
c18 -10 19 -25 19 -368 0 -265 -3 -361 -12 -370 -17 -17 -78 -15 -100 5 -17
15 -18 40 -18 361 0 307 2 347 17 364 18 20 64 24 94 8z m-2741 -506 c0 -81
-2 -89 -30 -124 -135 -170 -77 -416 118 -502 71 -31 180 -30 254 4 76 35 141
103 168 176 41 108 15 256 -57 324 -21 19 -23 30 -23 115 l0 93 405 0 405 0 0
-93 c0 -85 -2 -96 -23 -115 -13 -12 -34 -44 -48 -70 -20 -40 -24 -61 -24 -142
0 -73 4 -103 19 -130 38 -71 86 -119 152 -151 160 -79 345 -15 425 146 53 108
39 240 -36 334 -34 43 -35 46 -35 132 l0 89 405 0 405 0 0 -91 c0 -87 -2 -93
-29 -124 -72 -83 -92 -236 -43 -337 65 -132 210 -206 350 -178 241 50 338 328
181 520 -27 32 -29 41 -29 123 l0 88 283 -3 282 -3 47 -28 c26 -15 59 -42 72
-60 54 -71 56 -86 56 -523 l0 -404 -2125 0 -2125 0 0 404 c0 282 4 413 12 437
27 78 95 148 164 169 16 4 118 9 227 9 l197 1 0 -86z m2860 -375 c-30 -111
-161 -160 -257 -96 -36 24 -73 83 -73 116 0 21 1 21 40 -5 76 -50 223 -41 275
16 25 27 28 20 15 -31z m-2774 16 c69 -47 184 -48 253 -2 40 27 41 27 41 6 0
-35 -42 -98 -79 -122 -70 -42 -175 -22 -221 44 -20 26 -44 99 -34 99 2 0 20
-11 40 -25z m1277 -20 c70 -29 215 -9 241 34 14 23 24 10 16 -22 -26 -115
-159 -170 -257 -105 -41 27 -73 79 -73 119 0 21 1 21 18 6 10 -9 34 -23 55
-32z m2287 -770 l0 -65 -1023 0 c-1016 0 -1023 0 -1045 -20 -32 -30 -30 -82 4
-109 27 -21 30 -21 1045 -21 l1019 0 0 -609 c0 -600 0 -609 21 -635 26 -33 81
-36 107 -6 16 19 17 75 22 728 l5 707 204 -1325 c112 -729 206 -1354 208
-1390 7 -84 -21 -155 -80 -206 -60 -51 -117 -64 -279 -64 -76 0 -138 3 -138 6
0 3 12 25 26 50 52 89 54 106 54 683 l0 535 -22 23 c-30 33 -80 31 -107 -3
-21 -26 -21 -36 -21 -558 l0 -531 -24 -50 c-29 -62 -68 -102 -126 -129 l-45
-21 -1910 -3 c-2125 -2 -1976 -7 -2056 65 -22 20 -50 55 -62 77 l-22 41 -3
1308 -2 1307 949 0 950 0 25 23 c36 32 39 74 6 107 -20 20 -33 20 -975 20
l-955 0 0 65 0 65 2125 0 2125 0 0 -65z"
      />
      <path
        d="M1441 2629 c-53 -22 -67 -80 -28 -117 22 -21 33 -22 179 -22 143 0
156 2 173 20 25 28 23 76 -4 103 -20 20 -32 22 -159 24 -75 1 -148 -2 -161 -8z"
      />
      <path
        d="M2124 2626 c-48 -22 -60 -80 -24 -116 18 -18 33 -20 175 -20 142 0
157 2 175 20 27 27 26 81 -1 106 -18 16 -40 19 -158 22 -100 2 -144 -2 -167
-12z"
      />
      <path
        d="M2820 2633 c-33 -12 -50 -37 -50 -74 0 -62 19 -69 197 -69 144 0 153
1 173 22 37 40 22 98 -31 117 -32 11 -261 14 -289 4z"
      />
      <path
        d="M3503 2630 c-32 -13 -56 -54 -48 -82 17 -56 26 -58 196 -58 l158 0
20 26 c12 15 21 33 21 40 0 29 -25 64 -53 74 -36 12 -263 12 -294 0z"
      />
      <path
        d="M737 2019 c-46 -27 -41 -105 9 -128 15 -6 82 -11 165 -11 135 0 141
1 164 25 14 13 25 36 25 50 0 14 -11 37 -25 50 -23 24 -28 25 -172 25 -85 0
-156 -5 -166 -11z"
      />
      <path
        d="M1415 2005 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 23 -24 29
-25 164 -25 153 0 185 8 199 50 11 32 -7 84 -33 94 -9 3 -82 6 -161 6 -141 0
-146 -1 -169 -25z"
      />
      <path
        d="M2102 2007 c-31 -33 -29 -80 4 -106 24 -19 40 -21 169 -21 129 0 145
2 169 21 34 27 36 77 3 107 -22 21 -33 22 -173 22 -144 0 -151 -1 -172 -23z"
      />
      <path
        d="M2792 2010 c-35 -32 -33 -74 4 -107 24 -22 34 -23 170 -23 141 0 146
1 169 25 34 33 33 77 -1 104 -24 19 -40 21 -173 21 -134 0 -149 -2 -169 -20z"
      />
      <path
        d="M3487 2019 c-46 -27 -41 -105 9 -128 15 -6 82 -11 165 -11 135 0 141
1 164 25 14 13 25 36 25 50 0 14 -11 37 -25 50 -23 24 -28 25 -172 25 -85 0
-156 -5 -166 -11z"
      />
      <path
        d="M753 1420 c-32 -13 -56 -55 -48 -82 17 -56 26 -58 196 -58 l158 0 20
26 c27 35 26 57 -3 91 -24 28 -25 28 -163 30 -76 1 -148 -2 -160 -7z"
      />
      <path
        d="M1441 1420 c-53 -23 -67 -81 -28 -118 22 -21 33 -22 179 -22 143 0
156 2 173 20 25 28 23 76 -4 103 -20 20 -32 22 -159 24 -76 2 -148 -2 -161 -7z"
      />
      <path
        d="M2124 1416 c-48 -22 -60 -80 -24 -116 18 -18 33 -20 175 -20 142 0
157 2 175 20 25 25 26 71 1 101 -18 23 -26 24 -158 27 -101 2 -146 -2 -169
-12z"
      />
      <path
        d="M2825 1423 c-36 -9 -55 -34 -55 -73 0 -65 15 -70 193 -70 144 0 159
2 177 20 38 38 22 100 -31 119 -31 11 -244 14 -284 4z"
      />
      <path
        d="M3503 1420 c-32 -13 -56 -55 -48 -82 17 -56 26 -58 196 -58 l158 0
20 26 c27 35 26 57 -3 91 -24 28 -25 28 -163 30 -76 1 -148 -2 -160 -7z"
      />
      <path
        d="M733 805 c-11 -8 -24 -26 -27 -40 -7 -29 15 -79 39 -89 9 -3 82 -6
161 -6 141 0 146 1 169 25 14 13 25 36 25 50 0 14 -11 37 -25 50 -23 24 -28
25 -172 25 -115 0 -153 -3 -170 -15z"
      />
      <path
        d="M1415 795 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 23 -24 28
-25 172 -25 158 1 174 5 192 51 11 30 -8 83 -34 93 -9 3 -82 6 -161 6 -141 0
-146 -1 -169 -25z"
      />
      <path
        d="M2106 799 c-19 -15 -26 -30 -26 -54 0 -24 7 -39 26 -54 24 -19 40
-21 169 -21 129 0 145 2 169 21 34 27 36 77 3 107 -22 21 -33 22 -169 22 -132
0 -148 -2 -172 -21z"
      />
      <path
        d="M2798 806 c-43 -32 -38 -103 8 -125 15 -6 82 -11 165 -11 135 0 141
1 164 25 34 33 33 77 -1 104 -24 19 -40 21 -173 21 -104 -1 -151 -4 -163 -14z"
      />
    </g>
  </svg>
</template>

<style scoped></style>
