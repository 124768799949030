import api from "@/core/axios";

class Client {
  async $fetch(method, path, options = {}) {
    const instance = api({});
    let res;
    if (method.toUpperCase() === "GET") {
      if (options.queryParams) {
        return instance.get(path, { params: options.queryParams }).then(function (response) {
          return response;
        });
      }
      return await instance.get(path);
    }

    if (method.toUpperCase() === "POST") {
      const body = options.body || options.formData;
      return await instance.post(path, body);
    }

    if (method.toUpperCase() === "PUT") {
      return await instance.put(path, options.body);
    }

    if (method.toUpperCase() === "DELETE") {
      return await instance.delete(path);
    }

    if (method.toUpperCase() === "PATCH") {
      return instance.patch(path, options.body);
    }
  }
}

export default Client;