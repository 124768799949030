import Client from "./Client.js";

const __getPath = userId => (userId ? `/partner/user/${userId}` : `/dashboard`);
class AppSDK extends Client {
  baseUrl = process.env.VUE_APP_B2B_BASEURL || "https://b2b-api.loopcv.com";

  "loop.sendTestEmail"(body) {
    return this.$fetch("POST", "/dashboard/send-test-email", { body });
  }

  "loop.create"(body) {
    return this.$fetch("POST", "/dashboard/loop", { body });
  }

  "loop.edit"(loopId, body) {
    return this.$fetch("PUT", `/dashboard/loop/${loopId}`, { body });
  }

  "loop.delete"(loopId) {
    return this.$fetch("DELETE", `/dashboard/loop/${loopId}`);
  }

  "loop.show"(loopId, userId) {
    return this.$fetch("GET", `${__getPath(userId)}/loop/${loopId}`);
  }

  "loop.list"() {
    return this.$fetch("GET", `/dashboard/loops`);
  }

  "loop.examination"(loopId, queryParams = {}, isPartner = false, userId = null) {
    if (isPartner) {
      return this.$fetch("GET", `/partner/user/${userId}/loop/${loopId}/examination`, {
        queryParams,
      });
    } else {
      return this.$fetch("GET", `/dashboard/loop/${loopId}/examination`, {
        queryParams,
      });
    }
  }

  "loop.reasons"(userId, loopId) {
    return this.$fetch("GET", `${__getPath(userId)}/loop/${loopId}/reasons`);
  }

  "loop.companies"(query) {
    return this.$fetch("GET", `/dashboard/companies?query=${query}`);
  }

  "match.all.list"(
    userId,
    queryParams = {
      page: 1,
      filter: "all",
      version: "fast",
      perPage: 10,
      sort: "tag:-1",
      easyApply: "",
      platform: "all",
    },
  ) {
    return this.$fetch("GET", `${__getPath(userId)}/matches`, { queryParams });
  }

  "match.list"(
    loopId,
    queryParams = {
      page: 1,
      filter: "all",
      version: "fast",
      perPage: 10,
      sort: "tag:-1",
      platform: "all",
    },
  ) {
    return this.$fetch("GET", `/dashboard/loop/${loopId}/matches`, {
      queryParams,
    });
  }

  "partner.match.list"(
    userId,
    loopId,
    queryParams = {
      page: 1,
      filter: "all",
      version: "fast",
      perPage: 10,
      sort: "tag:-1",
      easyApply: "",
      platform: "all",
    },
  ) {
    return this.$fetch("GET", `/partner/user/${userId}/loop/${loopId}/matches`, { queryParams });
  }

  "match.questions"(userId, matchId) {
    return this.$fetch("GET", `${__getPath(userId)}/match/${matchId}/questions`);
  }

  "submit.match.questions"(userId, matchId, userAnswers) {
    return this.$fetch("POST", `${__getPath(userId)}/match/${matchId}/questions`, {
      body: { userAnswers },
    });
  }

  "match.show"(userId, matchId) {
    return this.$fetch("GET", `${__getPath(userId)}/match/${matchId}`);
  }

  "match.rate"(matchId, body) {
    return this.$fetch("PUT", `/match/${matchId}`, { body });
  }

  "match.manualApplication"(matchId, userId) {
    return this.$fetch("POST", `${__getPath(userId)}/match/${matchId}/manualApplication`);
  }

  "match.delete"(matchId) {
    return this.$fetch("DELETE", `/dashboard/match/${matchId}`);
  }

  // used to get the popularTemplates
  "emails.getTemplates"() {
    return this.$fetch("GET", "/dashboard/get-email-templates");
  }

  "template.list"(userId = null) {
    return this.$fetch("GET", `${__getPath(userId)}/templates`);
  }
  "template.create"(userId = null, body) {
    return this.$fetch("POST", `${__getPath(userId)}/template`, { body });
  }
  "template.edit"(userId = null, templateId, body) {
    return this.$fetch("PUT", `${__getPath(userId)}/template/${templateId}`, {
      body,
    });
  }
  "template.delete"(userId = null, templateId) {
    return this.$fetch("DELETE", `${__getPath(userId)}/template/${templateId}`);
  }

  /**
   * Get job level
   */
  "jobs.getTypes"() {
    return this.$fetch("GET", "/dashboard/get-job-type");
  }

  /**
   * Get job type
   */
  "jobs.getLevels"() {
    return this.$fetch("GET", "/dashboard/get-job-level");
  }

  /**
   * Get job type
   */
  "jobs.getCity"(location) {
    return this.$fetch("GET", `/dashboard/get-city/${location}`);
  }

  /**
   * Get job titles, for autocomplete
   */
  "jobs.getByName"(jobName) {
    return this.$fetch("GET", `/dashboard/get-job-by-name/${jobName}`);
  }

  "user.partialCreate"(body) {
    return this.$fetch("POST", `/dashboard/user`, { body });
  }

  // get the info of currently authenticated user
  // or the specified client of a partner
  // error 404 if user does not exist on mongodb
  "user.get"(userId) {
    return this.$fetch("GET", userId ? `/partner/user/${userId}` : "/dashboard/user");
  }

  // body needs a userId field
  // TODO update all usages
  "user.updateInfo"(body) {
    return this.$fetch("PUT", `/dashboard/user`, { body });
  }

  "user.getStats"(id, queryParams) {
    return this.$fetch("GET", `/dashboard/user/${id}/stats`, { queryParams });
  }

  "user.overviewStats"(fromDate, toDate) {
    return this.$fetch("GET", `/dashboard/overview?toDate=${toDate}&fromDate=${fromDate}`);
  }

  "partner.overviewStats"(fromDate, toDate) {
    return this.$fetch("GET", `/partner/overview?toDate=${toDate}&fromDate=${fromDate}`);
  }

  /**
   *
   * @param {*} body
   */
  "user.invite"(body) {
    return this.$fetch("POST", `/dashboard/invite-user`, { body });
  }

  "user.delete"() {
    return this.$fetch("DELETE", `/dashboard/user`);
  }

  //#region Payments
  "payments.getPrices"() {
    return this.$fetch("GET", "/prices");
  }

  "admin.subscription.get"() {
    return this.$fetch("GET", "/partner/billing");
  }

  "payments.getDiscount"(promoCode) {
    return this.$fetch("GET", `/dashboard/payments/getDiscount`, {
      queryParams: { code: promoCode },
    });
  }

  "payments.createSubscription"(body) {
    return this.$fetch("POST", `/dashboard/payments/createSubscription`, {
      body,
    });
  }

  "payments.cancelSubscription"(reason, plan) {
    const body = { reason };
    if (plan) {
      body.plan = plan;
    }
    return this.$fetch("POST", `/dashboard/payments/cancelSubscription`, {
      body: body,
    });
  }
  //#endregion

  "incomingEmails.get"() {
    return this.$fetch("GET", `/dashboard/incomingemails`);
  }

  "profile.questions"() {
    return this.$fetch("GET", `/dashboard/questions`);
  }

  "cvs.list"(userId = null) {
    const endpoint = !!userId ? `/partner/user/${userId}/cvs` : `/dashboard/cvs`;
    return this.$fetch("GET", endpoint);
  }

  "cvs.delete"(userId = null, cvId) {
    return this.$fetch("DELETE", `${__getPath(userId)}/cvs/${cvId}`);
  }

  "cvs.upload"(file, userId = null) {
    const formData = new FormData();
    formData.append("cv", file);

    const endpoint = !!userId ? `/partner/user/${userId}/cvs` : "/dashboard/cvs";
    return this.$fetch("POST", endpoint, {
      formData,
      noContentType: true,
    });
  }

  // only used by users/managed-users
  "cvs.setDefault"(cvId) {
    return this.$fetch("PUT", "/dashboard/loops", { body: { cvId } });
  }

  "colaberry.signup"(data) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const colaberryApiKey = "b3e736d0-f0c9-11ec-84c7-51b74d9726b1";
    formData.append("apiKey", colaberryApiKey);
    return this.$fetch("POST", `/formUser`, {
      formData,
      noContentType: true,
    });
  }

  "partner.childUser.list"(queryParams) {
    return this.$fetch("GET", `/partner/users`, { queryParams });
  }

  // body needs userEmail, firstName, lastName, country
  "partner.childUser.create"(body) {
    return this.$fetch("POST", `/partner/user`, { body });
  }

  "partner.childUser.update"(userId, body) {
    return this.$fetch("PUT", `/partner/user/${userId}`, { body });
  }

  "partner.childUser.status"(userId, body) {
    return this.$fetch("PUT", `/partner/user/${userId}/status`, { body });
  }

  "partner.childUser.updateSMTP"(userId, body) {
    return this.$fetch("PUT", `/partner/user/${userId}/smtp`, { body });
  }

  "partner.childUser.changePassword"(userId, body) {
    return this.$fetch("PUT", `/partner/user/${userId}/password`, { body });
  }

  "partner.childUser.updateGmail"(userId, body) {
    return this.$fetch("POST", `/partner/user/${userId}/gmail`, { body });
  }

  "partner.childUser.overviewStats"(userId) {
    return this.$fetch("GET", `/partner/user/${userId}/overview`);
  }

  "partner.loop.list"(userId) {
    return this.$fetch("GET", `/partner/user/${userId}/loops`);
  }

  "partner.loop.create"(userId, body) {
    return this.$fetch("POST", `/partner/user/${userId}/loop`, {
      body,
    });
  }

  "partner.loop.copy"(userId, loopId, body) {
    return this.$fetch("POST", `/partner/user/${userId}/loop/${loopId}/copy`, {
      body,
    });
  }

  "partner.loop.edit"(userId, loopId, body) {
    // TODO: change `loop` to be `loops`
    return this.$fetch("PUT", `/partner/user/${userId}/loop/${loopId}`, {
      body,
    });
  }

  "partner.loop.edit.name"(userId, loopId, body) {
    return this.$fetch("PUT", `/partner/user/${userId}/loop/${loopId}/name`, {
      body,
    });
  }

  "partner.loop.partnerNotes.create"(userId, loopId, body) {
    return this.$fetch("PUT", `/partner/user/${userId}/loop/${loopId}/note`, {
      body,
    });
  }

  "partner.loop.sharedNotes.create"(userId, loopId, body) {
    return this.$fetch("POST", `/partner/user/${userId}/loop/${loopId}/sharedNote`, {
      body,
    });
  }

  "partner.loop.sharedNotes.list"(userId, loopId) {
    return this.$fetch("GET", `/partner/user/${userId}/loop/${loopId}/sharedNotes`, {});
  }

  "partner.loop.delete"(userId, loopId) {
    // TODO: change `loop` to be `loops`
    return this.$fetch("DELETE", `/partner/user/${userId}/loop/${loopId}`);
  }

  "partner.jobs.export"(userId, queryParams = {}) {
    return this.$fetch("GET", `/partner/user/${userId}/jobs/export`, {
      queryParams,
    });
  }

  "loop.jobs.export"(loopId, queryParams = {}) {
    return this.$fetch("GET", `/dashboard/loop/${loopId}/jobs/export`, {
      queryParams,
    });
  }

  // apply to many matches on demand, via email or form application
  // body is { matchIds: ["id1", "id2"] }
  "partner.applyOnDemand"(userId, body) {
    return this.$fetch("POST", `${__getPath(userId)}/apply`, {
      body,
    });
  }

  "partner.applyManuallyClick"(matchId) {
    return this.$fetch("POST", `/partner/matches/${matchId}/applyManually`, {
      body: {},
    });
  }

  //#region Kanban APIs
  "kanban.get"() {
    return this.$fetch("GET", `/dashboard/board/default`);
  }
  "kanban.create"(body) {
    return this.$fetch("POST", `/dashboard/board/default/column/default/job`, { body });
  }
  "kanban.edit"(kanbanJobId, body) {
    return this.$fetch("PUT", `/dashboard/kanbanJob/${kanbanJobId}/details`, { body });
  }

  "kanban.move"(kanbanJobId, body) {
    return this.$fetch("PUT", `/dashboard/kanbanJob/${kanbanJobId}/position`, { body });
  }
  "kanban.delete"(kanbanJobId) {
    return this.$fetch("DELETE", `/dashboard/kanbanJob/${kanbanJobId}`);
  }
  //#endregion

  //#region Smtp
  "smtp.update"(body) {
    return this.$fetch("PUT", `/dashboard/user/smtp`, {
      body,
    });
  }

  "smtp.delete"(body) {
    return this.$fetch("DELETE", `/dashboard/user/smtp`);
  }
  //#endregion

  "stripe.checkout"(body) {
    return this.$fetch("POST", "/dashboard/checkout", { body });
  }

  "partner.childUser.updateScreeningQuestions"(userId, body) {
    return this.$fetch("PUT", `/partner/user/${userId}/screening-questions`, { body });
  }
}

export default new AppSDK();
