import Client from "./Client.js";

class AuthSDK extends Client {
  baseUrl = process.env.VUE_APP_B2B_BASEURL || "https://b2b-api.loopcv.com";

  "auth.login"(data) {
    return this.$fetch("POST", `/auth/login`, { body: data, noAuth: true });
  }

  "auth.googleLogin"(data) {
    return this.$fetch("POST", `/auth/google`, { body: data, noAuth: true });
  }

  "auth.signup"(data) {
    return this.$fetch("POST", `/auth/signup`, { body: data, noAuth: true });
  }

  "auth.session"(data) {
    return this.$fetch("POST", `/auth/session`, { body: data, noAuth: true });
  }

  "password.forgot"(data) {
    return this.$fetch("POST", `/auth/forgot`, { body: data, noAuth: true });
  }

  // called after forgot password
  "password.reset"(data) {
    return this.$fetch("POST", `/auth/reset`, { body: data, noAuth: true });
  }

  // from the profile tab, the user is already logged in
  "password.update"(data) {
    return this.$fetch("PUT", `/dashboard/user/password`, { body: data });
  }

  "user.info"() {
    return this.$fetch("GET", `/dashboard/get-info`);
  }
}

export default new AuthSDK();
