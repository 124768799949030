<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="14px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2492 4897 c-50 -19 -102 -65 -123 -111 -18 -40 -19 -106 -19 -1941
l0 -1900 -362 362 c-378 377 -409 402 -489 403 -84 0 -159 -47 -195 -124 -29
-62 -30 -117 -3 -176 27 -61 1103 -1140 1167 -1172 57 -27 136 -27 188 0 23
12 274 256 605 589 521 523 567 572 579 615 40 152 -106 303 -259 266 -42 -10
-76 -40 -421 -384 -207 -206 -379 -374 -383 -374 -4 0 -7 855 -7 1900 0 1862
0 1902 -19 1939 -50 97 -161 143 -259 108z"
      />
    </g>
  </svg>
</template>
