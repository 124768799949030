<template>
  <v-alert
    density="compact"
    text
    variant="tonal"
    v-if="clientName"
    class="w-100 mb-5"
    :color="primaryColor"
  >
    <v-icon class="mr-2" color="info">md:info</v-icon>
    <span>
      Viewing as: <b>{{ clientName || clientId }}</b>
    </span>
    <template #append>
      <v-tooltip location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            to="/clients"
            icon
            size="small"
            color="red"
            v-bind="props"
            variant="text"
          >
            <app-icon name="close" />
          </v-btn>
        </template>
        <span>Back to all clients</span>
      </v-tooltip>
    </template>
  </v-alert>
</template>

<script>
import appSDK from "@/client/AppSDK";

export default {
  name: "ClientAlert",
  data: () => ({
    client: {},
  }),
  props: {
    clientId: {
      required: true,
    },
  },
  created() {
    this.fetchClientInfo();
  },
  computed: {
    clientName() {
      return `${this.client.firstName} ${this.client.lastName} ${
        this.client.email ? `(${this.client.email})` : ""
      }`;
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
  },
  methods: {
    async fetchClientInfo() {
      try {
        const results = await appSDK["user.get"](this.clientId);
        this.client = { ...results };
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
