<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
-662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
-565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45
498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132
296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180
476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193
-740 235 -147 23 -475 34 -615 20z m585 -339 c637 -107 1177 -462 1531 -1006
163 -251 279 -558 331 -880 24 -153 24 -497 0 -650 -106 -652 -456 -1196
-1001 -1553 -258 -170 -563 -286 -891 -339 -153 -24 -497 -24 -650 0 -656 106
-1202 460 -1561 1012 -163 251 -279 558 -331 880 -24 153 -24 497 0 650 106
656 460 1202 1012 1561 291 189 661 315 1025 348 108 10 418 -4 535 -23z"
      />
      <path
        d="M2500 3989 c-29 -12 -67 -46 -83 -74 -7 -11 -14 -71 -17 -134 l-5
-114 -80 -22 c-111 -30 -176 -58 -259 -112 -335 -217 -392 -612 -127 -882 154
-156 334 -230 609 -250 75 -6 163 -17 195 -26 137 -36 256 -127 290 -221 22
-62 22 -86 0 -148 -39 -110 -179 -203 -353 -234 -164 -29 -326 5 -458 95 -81
56 -118 67 -171 53 -80 -21 -132 -104 -116 -187 17 -91 203 -214 400 -262 l70
-17 5 -116 c5 -126 14 -148 74 -192 39 -29 133 -29 172 0 60 44 69 66 74 191
l5 116 80 22 c111 30 176 58 259 112 335 217 392 612 127 882 -154 156 -334
230 -609 250 -75 6 -163 17 -195 26 -139 36 -256 126 -290 221 -22 62 -22 86
0 148 40 111 178 203 353 234 164 29 326 -5 458 -95 81 -56 118 -67 171 -53
80 21 132 104 116 187 -17 92 -201 211 -405 263 l-65 16 -5 116 c-5 125 -14
148 -72 191 -31 23 -111 32 -148 16z"
      />
    </g>
  </svg>
</template>
