<template>
  <div class="block">
    <label class="input-title header5"
      >{{ title }} <span class="required">{{ required == true ? "*" : "" }}</span></label
    >
    <div :class="inputBlockClass" @click="handleShowMenu">
      <!-- Input menu -->
      <div :class="selectedValueBlockClass">
        <span v-if="inputValues == null" class="placeholder">
          {{ placeholder }}
        </span>
        <div v-if="inputValues != null">
          <span :class="selectedValueClass" :key="val" v-for="val of inputValues">
            <label>{{ val }}</label>
          </span>
        </div>
      </div>
      <span class="icons">
        <ArrowDownIcon v-if="!this.showOptions && !this.uneditable" />
        <ArrowUpIcon v-if="this.showOptions && !this.uneditable" />
      </span>

      <!-- Options menu -->
      <div class="options-block" v-if="showOptions">
        <label
          :class="values.includes(option) ? optionClass + ' option-selected' : optionClass"
          :key="option"
          v-for="option in options"
          @click="handleOption(option)"
          ><span>{{ option }}</span
          ><Check v-if="values.includes(option)"
        /></label>
      </div>
    </div>
    <label class="error-message header7">{{ error }}</label>
  </div>
</template>

<script>
// @ts-nocheck

import { ArrowDownIcon, ArrowUpIcon } from "@/assets/fonts/icons/index";
import Check from "vue-material-design-icons/Check.vue";
export default {
  components: {
    ArrowDownIcon,
    ArrowUpIcon,
    Check,
  },

  props: {
    title: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    error: {
      type: String,
      default: "",
    },
    values: {
      type: [Array, String],
    },
    uneditable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Array, String],
    },
  },

  data() {
    return {
      showOptions: false,
      inputBlockClass: "input-block",
      optionClass: "option button2",
      selectedValueBlockClass: "selected-value-block",
      selectedValueClass: "selected-value header7",
    };
  },

  watch: {
    error(value) {
      if (value != "") {
        this.inputBlockClass = this.inputBlockClass + " hasError";
      } else {
        this.inputBlockClass = "input-block";
      }
    },
  },

  created() {
    if (this.uneditable) {
      this.inputBlockClass = this.inputBlockClass + " uneditable-input-block";
      this.selectedValueClass = this.selectedValueClass + " uneditable-selected-value";
    }
  },

  computed: {
    inputValues: {
      get() {
        if (this.values.length > 0) {
          return this.values;
        } else {
          return null;
        }
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    handleShowMenu() {
      if (this.uneditable == true) {
        this.showOptions = false;
      } else {
        this.showOptions = !this.showOptions;
      }
    },

    handleOption(val) {
      let values = this.values;
      if (values.includes(val)) {
        values = this.values.filter(v => v !== val);
      } else {
        values.push(val);
      }
      this.$emit("input", values);
    },
  },
};
</script>

<style scoped>
.block {
  display: flex;
  flex-direction: column;
}

.input-title {
  color: #989696;
}

.required {
  color: var(--blue);
}

/* input */
.input-block {
  margin-top: 7px;
  border: 1px solid #acacaf;
  border-radius: 6px;
  display: inline-block;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 2px;
  padding-right: 18px;
  color: var(--black);
  position: relative;
  box-shadow: 0.1px 0.1px 10px lightgrey;
  cursor: pointer;
}
.input-block:focus-within {
  border: 1px solid var(--blue);
  border-radius: 6px;
  opacity: 1;
  outline-width: 0;
}

.placeholder {
  color: #989696;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
}

/* Selected value */
.selected-value-block {
  display: flex;
  flex-direction: row;
}
.selected-value {
  color: transparent;
  text-shadow: 0 0 0 #35383c;
  width: -webkit-fill-available;
  cursor: pointer;
  background-color: #f8f8fa;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selected-value:focus {
  outline: none;
}

.selected-value,
.selected-value:focus-visible {
  border: 0;
  outline-width: 0;
}

/* uneditable input */
.uneditable-input-block {
  cursor: context-menu;
  background-color: #f8f8fa;
  border: 0;
}

.uneditable-selected-value {
  border: 0;
  cursor: context-menu;
  background-color: #e5e5ea;
}

/* error input */
.hasError {
  border: 1px solid #d8685e;
  border-radius: 6px;
  opacity: 1;
}

.error-message {
  color: #d8685e;
  margin-top: 2px;
  margin-bottom: 5px;
}

/* icons input */
.icons {
  color: #acacaf;
  cursor: pointer;
  left: auto;
  margin-bottom: -5px;
  display: inline-flex;
}

.icons span {
  margin-left: 5px;
}

.icons span:focus,
.icons span:focus-visible,
.icons span:hover {
  color: #989696 !important;
  outline-width: 0;
}

/* options */
.options-block {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 24px #696d7729;
  border-radius: 6px;
  opacity: 1;
  padding-left: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 18px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 55px;
  z-index: 10;
  width: 100%;
  left: 0;
  max-height: 220px;
  overflow-y: auto;
  box-shadow: 5px 5px 10px #888888;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.option:hover {
  cursor: pointer;
  background-color: #f8f8fa;
  border-radius: 10px;
}

.option-selected {
  color: var(--blue);
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

<!-- Example
  <MultipleDropdown
      :title="'Experience'"
      :required="true"
      :placeholder="'Select your'"
      :value="'Entry'"
      @input="handleDropdown"
      :options="['Entry', 'Middle', 'Senior', 'Associate', 'Associate']"
       :error="'Κατι παει στραβα'"
       uneditable:true
  />
-->
