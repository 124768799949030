<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="selected ? '#175c9f' : '#000000'"
      :stroke="selected ? '#175c9f' : '#000000'"
      :stroke-width="selected ? '50' : '30'"
    >
      <path
        d="M2303 4785 c-963 -123 -1721 -808 -1929 -1744 -19 -89 -24 -125 -15
-130 17 -11 448 -74 459 -67 5 3 12 29 16 57 18 131 106 367 198 531 138 246
381 495 623 637 514 304 1128 332 1663 76 277 -133 494 -317 684 -580 l87
-120 -284 -3 -285 -2 0 -160 0 -160 640 0 640 0 0 640 0 640 -160 0 -160 0 0
-331 0 -331 -106 137 c-391 505 -863 795 -1459 896 -160 27 -458 34 -612 14z"
      />
      <path
        d="M4296 2268 c-2 -7 -10 -42 -16 -78 -21 -110 -85 -292 -145 -411 -212
-423 -582 -744 -1025 -889 -194 -64 -322 -84 -545 -84 -116 -1 -219 4 -265 13
-503 89 -888 329 -1182 736 l-87 120 284 3 285 2 0 160 0 160 -640 0 -640 0 0
-640 0 -640 160 0 160 0 0 331 0 331 107 -138 c249 -323 497 -532 824 -693
473 -235 1023 -292 1543 -160 694 175 1264 679 1530 1351 43 109 106 329 121
425 8 50 31 42 -233 83 -219 34 -231 35 -236 18z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
